import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { getSolicAberta } from "../../../store/actions/CRTActions";
import { connect } from "react-redux";
import Loading from "../../../components/loading";
import { useState } from "react";
import Redirecionar from "../../../components/redirecionar";
import NotFound from "../../notFound";
import EstruturaDaPagina from "../../../components/estruturaDaPagina";
import TituloDaPagina from "../../../components/tituloDaPagina";

const Pendencias = ({ auth, solicitacaoAberta }) => {
  const [haPendencias, setHaPendencias] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getSolicAberta(auth.idusuario);
      if (res !== "5") {
        setHaPendencias(false);
      } else {
        setHaPendencias(true);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Box>
      {loading ? (
        <Loading status={loading} />
      ) : !haPendencias && !loading ? (
        <Redirecionar url={"/"} time={5}>
          <NotFound />
        </Redirecionar>
      ) : (
        <EstruturaDaPagina>
          <TituloDaPagina titulo="Alteração de CR" />
          <Typography>
            Há pendências no protocolo {solicitacaoAberta.solic}. Façam as
            alterações e envie novamente
          </Typography>
        </EstruturaDaPagina>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
  solicitacaoAberta: state.CRTReducer.solicitacaoAberta,
});
export default connect(mapStateToProps)(Pendencias);
