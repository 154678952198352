import axios from 'axios';

export const loginProfissional = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api4/profissional`,
  auth:{
      username:'farma_api',
      password:'4th3n@(2015)'
    
  } 
})

