import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";
const CustomSkeleton = () => {
    return(
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Skeleton
            variant="rectangular"
            height={"20vh"}
            animation="wave"
            sx={{ opacity: 0.3 }}
          />
          <Skeleton
            variant="rectangular"
            height={"20vh"}
            animation="wave"
            sx={{ opacity: 0.3 }}
          />
          <Skeleton
            variant="rectangular"
            height={"20vh"}
            animation="wave"
            sx={{ opacity: 0.3 }}
          />
        </Box>
    )
}

export default CustomSkeleton;