import { useState, useEffect } from "react";
import { Table, TableHead, TableRow, TableBody, TableCell} from "@mui/material";
import moment from "moment";
import { horasExibicao } from "../../../helpers";
import { semanaProfissional } from "../../../rotulos/semanaProfissional";
import { rotulosProfissional } from "../../../rotulos/profissional";

const TabelaFuncionario = ({ ehNovo, inicio, fim }) => {
  const [observacao, setObservacao] = useState('');

  useEffect(()=> {
    
  },[])
  return (
    <>
      {ehNovo ? (
        <Table size="small" sx={{ border: "solid thin gray", maxWidth: 650, mx:'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Campo</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fim.pro_tipo !== inicio?.pro_tipo && (
              <TableRow>
                <TableCell>Hierarquia</TableCell>
                <TableCell>{fim.pro_tipo}</TableCell>
              </TableRow>
            )}
            {fim.pro_vinculo !== inicio?.pro_vinculo && (
              <TableRow>
                <TableCell>Vínculo</TableCell>
                <TableCell>{fim.pro_vinculo}</TableCell>
              </TableRow>
            )}
            {fim.dataini !== inicio?.dataini && (
              <TableRow>
                <TableCell>Data início</TableCell>
                <TableCell>
                  {moment(fim.dataini).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            )}
            {fim.datafim !== inicio?.datafim && (
              <TableRow>
                <TableCell>Data fim</TableCell>
                <TableCell>
                  {fim.datafim === "0000-00-00"
                    ? ""
                    : moment(fim.datafim).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            )}
            {semanaProfissional.map(
              (dia) =>
                fim[dia] !== inicio?.[dia] && (
                  <TableRow>
                    <TableCell>{rotulosProfissional[dia]}</TableCell>
                    <TableCell>
                      {horasExibicao(fim[dia])}
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      ) : (
        <Table size="small" sx={{ border: "solid thin gray", maxWidth:750, mx:'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Campo</TableCell>
              <TableCell>Antes</TableCell>
              <TableCell>Depois</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fim.pro_tipo !== inicio?.pro_tipo && (
              <TableRow>
                <TableCell>Hierarquia</TableCell>
                <TableCell>{inicio?.pro_tipo}</TableCell>
                <TableCell>{fim.pro_tipo}</TableCell>
              </TableRow>
            )}
            {fim.pro_vinculo !== inicio?.pro_vinculo && (
              <TableRow>
                <TableCell>Vínculo</TableCell>
                <TableCell>{inicio?.pro_vinculo}</TableCell>
                <TableCell>{fim.pro_vinculo}</TableCell>
              </TableRow>
            )}
            {fim.dataini !== inicio?.dataini && (
              <TableRow>
                <TableCell>Data início</TableCell>
                <TableCell>
                  {inicio === undefined ||
                  inicio?.dataini === "0000-00-00"
                    ? ""
                    : moment(inicio?.dataini).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>
                  {moment(fim.dataini).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            )}
            {fim.datafim !== inicio?.datafim && (
              <TableRow>
                <TableCell>Data fim</TableCell>
                <TableCell>
                  {inicio === undefined ||
                  inicio?.datafim === "0000-00-00"
                    ? ""
                    : moment(inicio?.datafim).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>
                  {fim.datafim === "0000-00-00"
                    ? ""
                    : moment(fim.datafim).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            )}
            {semanaProfissional.map(
              (dia) =>
                fim[dia] !== inicio?.[dia] && (
                  <TableRow>
                    <TableCell>{rotulosProfissional[dia]}</TableCell>
                    <TableCell>
                      {inicio && horasExibicao(inicio[dia])}
                    </TableCell>
                    <TableCell>
                      {horasExibicao(fim[dia])}
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default TabelaFuncionario;
