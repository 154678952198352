import './App.css';

import Routers from './routers';
import {store,persistor} from './store';
import { Provider } from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';


// estilos
import { ThemeProvider } from '@mui/material/styles';
import theme from './assets/css/theme';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';

import { getToken } from './helpers';
import { apiEmpresa } from './services/apiEmpresa';
import { apiProfissional } from './services/apiProfissional';
import { apiFiles } from './services/apiFiles';
import { expirarSessao } from './store/actions/AutenticacaoActions';
import {interceptor} from './interceptor';

function App() {
const interceptorHTTP = interceptor();
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={<CircularProgress color="primary"/>} persistor={persistor}>
            <Routers/>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
