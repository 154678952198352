const statusTexto = {
    '1': 'Aberta',
    '2': 'Em Análise',
    '3':'Aguardando Pagamento',
    '4': 'Concluída',
    '5': 'Pendente',
    '6': 'Correção de dados realizada',
    '7':'Aguardando Preenchimento de Anexos',
    '8':'Cancelada',
    '9':'Realizado de Forma Presencial'
}

export default statusTexto;