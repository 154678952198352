import { getToken } from "./helpers";
import { apiEmpresa } from "./services/apiEmpresa";
import { apiProfissional } from "./services/apiProfissional";
import { expirarSessao } from "./store/actions/AutenticacaoActions";

export const interceptor = () => {
   
    // ADICIONA O TOKEN A CADA REQUEST
    apiEmpresa.interceptors.request.use(function (config) {
        const token = getToken();
        config.headers.Authorization = "Bearer " + token;
    
        return config;
      });
    
      apiProfissional.interceptors.request.use(function (config) {
        const token = getToken();
        config.headers.Authorization = "Bearer " + token;
    
        return config;
      });
    
    
      // CASO USUÁRIO NÃO AUTORIZADO (401) PEDE PARA DESLOGAR

      apiEmpresa.interceptors.response.use(response => {
        return response;
     }, error => {
      if (error.response.status === 401) {
        expirarSessao()
       }
       return error;
     });
    
     apiProfissional.interceptors.response.use(response => {
      return response;
    }, error => {
     if (error.response.status === 401) {
      expirarSessao()
     }
     return error;
    });
}
    