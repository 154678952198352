import {
  Button,
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";


import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";

const ModalImg = ({ handleClose, file, titulo }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState('');
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  useEffect(()=>{
    if(file.type === 'application/pdf'){
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setPdfFile(e.target.result);
      }
    }
  },[])
  return (
    <>
      <DialogTitle>
        <Toolbar sx={{display:'flex', justifyContent:'space-between'}}>
          {titulo}
          <CloseIcon onClick={() => handleClose()} sx={{float:'right', '&:hover':{cursor:'pointer'}}}/>
        </Toolbar>
      </DialogTitle>
      <DialogContent>
        {file &&
          (file.type === "application/pdf" ? (
            <>          
            <embed type='application/pdf' width='100%' height='700px' src={`${pdfFile}`}/>
            </>
          ) : (
            <img src={URL.createObjectURL(file)} alt={file.nome} width="100%" />
          ))}
      </DialogContent>
    </>
  );
};

export default ModalImg;
