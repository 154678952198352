import { Container, Typography } from "@material-ui/core";
import HeaderAtendimento from "./headerAtendimento";
import { Box } from "@mui/material";

import imgRodape from "../../../assets/images/rodape-rs.png";
import InfoAtendimento from "./infoAtendimento";
import moment from "moment";

const Atendimento = () => {
  let nroate = "";
  let nom = "";
  let obs = "";
  let dat = "";
  let hor = "";
  let detalhes_cpfcnpj_inscricao = "";
  let nro = "";
  let ass = "";
  let srv = "";
  let qtdCopias = "";
  let val = "";
  let procpf = "";
  let pronom = "";
  let now = "";

  return (
    <Container>
			<Box sx={{
				display:'flex',
				flexDirection:'column',
				gap:3,
				my:2
			}}>
      <HeaderAtendimento />
      <InfoAtendimento/>
			<Box>
        <small style={{ float: "right" }}>Data da impressão: {moment().format('DD/MM/YYYY')}</small>
        <img
          src={imgRodape}
          width="100%"
          alt="Rodapé da página com logo do CRF"
        />
      </Box>
			<InfoAtendimento/>
			</Box>
    </Container>
  );
};

export default Atendimento;
