import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Menu from "../../components/menu";
import { connect } from "react-redux";
import { getFiliais, getMenu } from "../../store/actions/AutenticacaoActions";
import { getCapitalSocial, getTipoEstab } from "../../store/actions/CRTActions";
import logoHorizontal from "../../assets/images/crfrs-simbolo-fundo-verde.svg";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../store/reducers";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import useWindowSize from "../../components/useWindowSize";
import { Outlet, useLocation } from "react-router-dom";
import EstruturaDaPagina from '../../components/estruturaDaPagina';
import SessaoExpirada from "../../components/sessaoExpirada";
import { Dialog } from "@mui/material";
import Loading from "../../components/loading";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function MenuManual({ auth, menus, dispatch, sessaoExpirada, listaFiliais, capitalSocial, tipoDeEstabelecimento }) {
  const size = useWindowSize();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    (async () => {
      const resposta = await getMenu(auth.tipoUsuario, auth.token);
      if(!capitalSocial.length) await getCapitalSocial(auth.tipoUsuario);
      if(!tipoDeEstabelecimento.length && auth.tipoUsuario === 'empresa') await getTipoEstab();

      if(listaFiliais === null && auth.tipoUsuario === 'empresa'){
        const haFiliais = await getFiliais(auth.idusuario);
        if(haFiliais) navigate('/selecionar-empresa')
      }
      if (resposta) setLoading(false);
    })();
  }, []);

 
  const sair = () => {
    const resposta = handleLogout(dispatch);
    if (resposta) {
      navigate("/login");
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (size.width <= 600) setOpen(false);
  }, [size]);
  return (
    <Box sx={{ display: "flex" }}>
      <Dialog open={sessaoExpirada} onClose={()=> sair()} scroll="paper">
        <SessaoExpirada acao={sair}/>
      </Dialog>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "secondary.main" }}
      >
        <Toolbar sx={{ justifyContent: "space-between", gap: 2 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {/* <img src={logoHorizontal} alt='crfrs' width='80px' /> */}
            <Typography
              variant="h6"
              noWrap
              component="div"
              onClick={() => navigate("/")}
              color="primary.main"
            >
              e-CRF
            </Typography>
          </Box>
          <Box
            onClick={() => sair()}
            sx={{
              display: "flex",
              gap: 1,
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            <Typography>Sair</Typography>
            <ExitToAppIcon />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            color: "primary.main",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Menu menus={menus} usuario={auth.tipoUsuario} />
      </Drawer>
      <Main open={open}>
      	<DrawerHeader />
        <EstruturaDaPagina>
        {loading ? (
          <Loading status={loading}/>
        ): (
          <Outlet />
        )} 
        </EstruturaDaPagina>
      </Main>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
  menus: state.AutenticacaoReducer.usuario.menu,
  sessaoExpirada: state.AutenticacaoReducer.sessaoExpirada,
  listaFiliais: state.AutenticacaoReducer.listaFiliais,
  capitalSocial: state.CRTReducer.capitalSocial,
  tipoDeEstabelecimento: state.CRTReducer.tipoDeEstabelecimento
});
export default connect(mapStateToProps)(MenuManual);
