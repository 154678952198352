import moment from "moment";


// compara se dois objetos são iguais. Entretanto, devem ter as chaves na mesma ordem.
export function objetosSaoIguais(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function removeMaskCpfOrCnpj(value) {
  return value.replace(/[^0-9]/g, "");
}

export function formatarHoraBD(value) {
  if (value === null) return null;
  return value
    .replace(/[\s\_]/g, "")
    .replace(/\+/g, ";")
    .split(/;:/)[0];
}

// retorna array de horas no formato hh:mm
export function arrayHoras(value) {
  return value.match(/\d{2}:\d{2}/g);
}

export function horasExibicao(value) {
  if (value === null || value === undefined || value === "") return null;
  return value.replace(/\-/g, " às ").replace(/;/g, "; ");
}

export function getToken() {
  let token = "";
  const session = JSON.parse(sessionStorage.getItem("persist:root"));
  if (session !== null) {
    token = JSON.parse(session.AutenticacaoReducer).autenticacao.token;
  }

  return token;
}

export function dataIgualBanco(value) {
  return /\d{4}-\d{2}-\d{2}/.test(value);
}

export function getTipoUsuario() {
  let usuario = "";

  const session = JSON.parse(sessionStorage.getItem("persist:root"));
  if (session !== null) {
    const { tipoUsuario } = JSON.parse(
      session.AutenticacaoReducer
    ).autenticacao;
    usuario = tipoUsuario;
  }

  return usuario;
}

export function dataAmericana (value) {
  if(removeMascaraData(value) ==='') return '0000-00-00'
  if(dataIgualBanco(value))  return value
  return moment(value,'DD/MM/YYYY').format('YYYY-MM-DD')
}

export function removeMascaraData (value) {
  return value.replaceAll('_','')
}

export function haInicioAposFim(horas){
  if(horas.length){
    for(let i=0; i<horas.length - 1;i++){
      let isAfter = moment(horas[i],'HH:mm').isAfter(moment(horas[i+1],'hh:mm'));
      if(isAfter) return true;
    }
  }
  return false;
}

export const formatarCapital = (dados) => {
   
  const novosDados = dados.map((d,index) => ({
      idcapitalsocial: d.idcapitalsocial,
      texto: d.maximo !== null ? (
          `${d.codigo} - de ${Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(d.minimo)} à ${Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(d.maximo)}`
      ): (
          `${d.codigo} - Acima de ${Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(d.minimo)}`
      )       
  }))
  
  return novosDados
}

export const formatarEndereco = (logradouro, numero, complemento, bairro) => {
  let endereco = logradouro;
  if(numero !== null && numero !=="") endereco+=`, ${numero}`;
  if(complemento !== null && complemento !=="") endereco+=` - ${complemento}`;
  if(bairro !==null && bairro !=="") endereco+=` - ${bairro}`;

  return endereco;
}

export function removerAcentos(s) {
  return s.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
}