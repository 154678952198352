import * as yup from "yup";
import moment from "moment";
import {
  removeMaskCpfOrCnpj,
  arrayHoras,
  dataIgualBanco,
  removeMascaraData,
  haInicioAposFim,
} from "../../../helpers";
import axios from "axios";

const cpfs = []

const validacaoFormulario = yup.object({
  empresa: yup.object().shape({
    nome: yup
      .string("Insira o nome fantasia")
      .required("Nome fantasia é um campo obrigatório"),
    razao: yup
      .string("Insira a razão social")
      .required("Razão social é um campo obrigatório"),
    capitalsocial_idcapitalsocial: yup
      .string("Selecione o capital social")
      .required("Capital social é um campo obrigatório"),
    idtipo: yup
      .string().test('idtipo','Selecione o tipo de estabelecimento', (val) => {
        return val!=='0'
      })
      .required('Tipo de estabelecimento é um campo obrigatório'),
    idsubtipo: yup 
      .string()
      .test('idsubtipo','Selecione o subtipo de estabelecimento', (val) => {
        return val !=="0"
      }),
    cep: yup.string()
      .test("cep", "CEP deve conter 8 dígitos", (val) => {
       if(val === undefined) return false;
        return removeMaskCpfOrCnpj(val).length === 8;
      })
      // .test('cep', "CEP não encontrado", async (val) => {
      //   if(val === undefined) return false
      //   val = removeMaskCpfOrCnpj(val);
      //   if(val.length !==8) return true;
      //   const {data} = await axios.get(`https://viacep.com.br/ws/${val}/json/`);
      //   return data.cep !== undefined
      // })
      
      ,
    logradouro: yup
      .string("Insira o logradouro")
      .required("Logradouro é um campo obrigatório"),
    cidade: yup
      .string("Insira a cidade")
      .required("Cidade é um campo obrigatório"),
    uf_endereco: yup
      .string("Insira a UF")
      .test("empresa.uf_endereco", "UF deve possuir 2 letras", (val) => {
        if (val === "") return false;
        return val.length === 2;
      })
      .required("UF é um campo obrigatório"),
    hr_seg: yup
      .string()
      .nullable(true)
      .test("empresa.hr_seg", "Horário inválido (segunda-feira)", (val) => {
        if (val === undefined || val === null) return true;
        const horarios = arrayHoras(val);
        if (horarios.length % 2 !== 0) return false;
        if (horarios.some((h) => h === "24:00")) return false;
        if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
          return false;
        return !haInicioAposFim(horarios);
      }),
    hr_ter: yup
      .string()
      .nullable(true)
      .test("hr_ter", "Horário inválido (terça-feira)", (val) => {
        if (val === undefined || val === null) return true;
        const horarios = arrayHoras(val);
        if (horarios.length % 2 !== 0) return false;
        if (horarios.some((h) => h === "24:00")) return false;
        if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
          return false;
        return !haInicioAposFim(horarios);
      }),
    hr_qua: yup
      .string()
      .nullable(true)
      .test("hr_qua", "Horário inválido (quarta-feira)", (val) => {
        if (val === undefined || val === null) return true;
        const horarios = arrayHoras(val);
        if (horarios.length % 2 !== 0) return false;
        if (horarios.some((h) => h === "24:00")) return false;
        if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
          return false;
        return !haInicioAposFim(horarios);
      }),
    hr_qui: yup
      .string()
      .nullable(true)
      .test("hr_qui", "Horário inválido (quinta-feira)", (val) => {
        if (val === undefined || val === null) return true;
        const horarios = arrayHoras(val);
        if (horarios.length % 2 !== 0) return false;
        if (horarios.some((h) => h === "24:00")) return false;
        if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
          return false;
        return !haInicioAposFim(horarios);
      }),
    hr_sex: yup
      .string()
      .nullable(true)
      .test("hr_sex", "Horário inválido (sexta-feira)", (val) => {
        if (val === undefined || val === null) return true;
        const horarios = arrayHoras(val);
        if (horarios.length % 2 !== 0) return false;
        if (horarios.some((h) => h === "24:00")) return false;
        if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
          return false;
        return !haInicioAposFim(horarios);
      }),
    hr_sab: yup
      .string()
      .nullable(true)
      .test("hr_sab", "Horário inválido (sábado)", (val) => {
        if (val === undefined || val === null) return true;
        const horarios = arrayHoras(val);
        if (horarios.length % 2 !== 0) return false;
        if (horarios.some((h) => h === "24:00")) return false;
        if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
          return false;
        return !haInicioAposFim(horarios);
      }),
    hr_dom: yup
      .string()
      .nullable(true)
      .test("hr_dom", "Horário inválido (domingo)", (val) => {
        if (val === undefined || val ===  null) return true;
        const horarios = arrayHoras(val);
        if (horarios.length % 2 !== 0) return false;
        if (horarios.some((h) => h === "24:00")) return false;
        if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
          return false;
        return !haInicioAposFim(horarios);
      }),
    email1: yup.string().nullable(true).email("Email inválido"),
    email2: yup.string().nullable(true).email("Email inválido"),
  }),
  lista: yup.array().of(
    yup.object().shape({
      cpf: yup
        .string()
        .test("cpf", "CPF deve conter 11 dígitos", (val) => {
          if (val === undefined) return true;
          return removeMaskCpfOrCnpj(val).length === 11;
        })
        .required("CPF é um campo obrigatório"),
      dtnasc: yup.string().test("dtnasc", "Data inválida", (val) => {
        if (dataIgualBanco(val)) return true;
        return moment(val, "DD/MM/YYYY", true).isValid();
      }),
      pro_tipo: yup.string().required("Hierarquia é um campo obrigatório"),
      pro_vinculo: yup.string().required("Vínculo é um campo obrigatório"),
      dataini: yup.string().test("dataini", "Data inválida", (val) => {
        if (val === "0000-00-00") return false;
        if (dataIgualBanco(val)) return true;
        return moment(val, "DD/MM/YYYY", true).isValid();
      }),
      datafim: yup
        .string()
        .nullable(true)
        .test("datafim", "Data inválida", (val) => {
          if (
            dataIgualBanco(val) ||
            val === undefined ||
            removeMascaraData(val) === ""
          )
            return true;
          return moment(val, "DD/MM/YYYY", true).isValid();
        })
        .test(
          "datafim",
          "Data final não pode ser menor que inicial",
          function (val) {
            if (val === "0000-00-00" || val === null || val === undefined)
              return true;
            let formatoIni = dataIgualBanco(this.parent["dataini"])
              ? "YYYY-MM-DD"
              : "DD/MM/YYYY";
            let formatoFim = dataIgualBanco(val) ? "YYYY-MM-DD" : "DD/MM/YYYY";
            return moment(this.parent["dataini"], formatoIni).isBefore(
              moment(val, formatoFim),
              "day"
            );
          }
        ),
      pro_hora_seg: yup
        .string()
        .nullable(true)
        .test("pro_hora_seg", "Horário inválido", (val) => {
          if (val === undefined || val === null) return true;
          if (val[0] === '_') return false;
          const horarios = arrayHoras(val);
          if (horarios.length % 2 !== 0) return false;
          if (horarios.some((h) => h === "24:00")) return false;
          if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
            return false;
          return !haInicioAposFim(horarios);
        }),
      pro_hora_ter: yup
        .string()
        .nullable(true)
        .test("pro_hora_ter", "Horário inválido", (val) => {
          if (val === undefined || val === null) return true;
          if (val[0] === '_') return false;
          const horarios = arrayHoras(val);
          if (horarios.length % 2 !== 0) return false;
          if (horarios.some((h) => h === "24:00")) return false;
          if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
            return false;
          return !haInicioAposFim(horarios);
        }),
      pro_hora_qua: yup
        .string()
        .nullable(true)
        .test("pro_hora_qua", "Horário inválido", (val) => {
          if (val === undefined || val === null) return true;
          if (val[0] === '_') return false;
          const horarios = arrayHoras(val);
          if (horarios.length % 2 !== 0) return false;
          if (horarios.some((h) => h === "24:00")) return false;
          if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
            return false;
          return !haInicioAposFim(horarios);
        }),
      pro_hora_qui: yup
        .string()
        .nullable(true)
        .test("pro_hora_qui", "Horário inválido", (val) => {
          if (val === undefined || val === null) return true;
          if (val[0] === '_') return false;
          const horarios = arrayHoras(val);
          if (horarios.length % 2 !== 0) return false;
          if (horarios.some((h) => h === "24:00")) return false;
          if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
            return false;
          return !haInicioAposFim(horarios);
        }),
      pro_hora_sex: yup
        .string()
        .nullable(true)
        .test("pro_hora_sex", "Horário inválido", (val) => {
          if (val === undefined || val === null) return true;
          if (val[0] === '_') return false;
          const horarios = arrayHoras(val);
          if (horarios.length % 2 !== 0) return false;
          if (horarios.some((h) => h === "24:00")) return false;
          if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
            return false;
          return !haInicioAposFim(horarios);
        }),
      pro_hora_sab: yup
        .string()
        .nullable(true)
        .test("pro_hora_sab", "Horário inválido", (val) => {
          if (val === undefined || val === null) return true;
          if (val[0] === '_') return false;
          const horarios = arrayHoras(val);
          if (horarios.length % 2 !== 0) return false;
          if (horarios.some((h) => h === "24:00")) return false;
          if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
            return false;
          return !haInicioAposFim(horarios);
        }),
      pro_hora_dom: yup
        .string()
        .nullable(true)
        .test("pro_hora_dom", "Horário inválido", (val) => {
          if (val === undefined || val === null) return true;
          if (val[0] === '_') return false;
          const horarios = arrayHoras(val);
          if(horarios===null) return true;
          if (horarios.length % 2 !== 0) return false;
          if (horarios.some((h) => h === "24:00")) return false;
          if (horarios.some((h) => moment(h, "hh:mm").isValid() === false))
            return false;
          return !haInicioAposFim(horarios);
        }),
    })
  ),
});

export default validacaoFormulario;
