import { loginInterno, loginInternoPorEmail } from "../../services/loginInterno";
import { loginEmpresa } from "../../services/loginEmpresa";
import { loginProfissional } from "../../services/loginProfissional";
import axios from "axios";
import { apiEmpresa } from "../../services/apiEmpresa";

import {store} from '../index'
import { getToken } from "../../helpers";

const dispatch = store.dispatch;

export const handleLogin = async(values) => {
 
  try{
     if(values.tipoUsuario==='empresa'){  
      const {data} = await loginEmpresa.post('',{username: values.cnpj,password: values.senha, grant_type:'password'});
      apiEmpresa.defaults.headers.common['Authorization'] = `Bearer ${data.access_token} `;
      dispatch({
        type: 'SET_LOGIN',
        token: data.access_token,
        estaLogado: true,
        tipoUsuario:data.scope,
        idusuario: data.idusuario,
        nome:data.razao
      })

      

      
    } else if(values.tipoUsuario==='profissional'){
      
      const {data} = await loginProfissional.post('',{username: values.cpf,password:values.senha, grant_type: 'password'});
      loginProfissional.defaults.headers.common['Authorization'] = values.cpf;
      dispatch({
        type: 'SET_LOGIN',
        token: data.access_token,
        estaLogado: true,
        tipoUsuario:data.scope,
        idusuario: data.idusuario,
        nome:data.nome || ''
      })
    }
    return true
  }catch (error){
    dispatch({
        type: 'SET_LOGIN',
        token:'',
        estaLogado: false
    })
    return false;
  }
  

}

export const setUsuario = (idusuario, nome, endereco) => {
  if(idusuario === null || idusuario === undefined) return false;
  dispatch({
    type:'SET_USUARIO',
    idusuario,
    nome,
    endereco 
  })

}

export const getMenu = async (tipoUsuario, token) => {
 
  if(tipoUsuario === null) return

    const url = `${process.env.REACT_APP_BASE_URL}/api4/${tipoUsuario}/menu`;

    try{
      const {data} = await axios.get(url, {headers: {
        Authorization:`Bearer ${token}`
      }})
      dispatch({
        type: 'GET_MENU',
        usuario: tipoUsuario,
        menu: data
      })
    } catch(error) {
      dispatch({
        type:'GET_MENU',
        usuario: tipoUsuario,
        menu: false
      })
    }
    return true;
}

export const expirarSessao = () => {
  dispatch({
    type:'SET_SESSAO_EXPIRADA',
    payload: true
  })
}

export const getFiliais = async (idEmp) => {
  const token = getToken();
  try{
    const {data} = await apiEmpresa.get('/listarFiliais', {
      headers:{
        Authorization:"Bearer " + token
      },
      params:{idEmp}
    });
   
    if(!Array.isArray(data) || data.length <= 1){
      dispatch({
        type:'SET_FILIAIS',
        payload: []
      })
      return false;
    }

    dispatch({
      type:'SET_FILIAIS',
      payload: data
    })

    return true;
  }
  catch(error){
    dispatch({
      type:'SET_FILIAIS',
      payload: []
    })
    return false
  }
} 