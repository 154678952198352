import { useEffect, useState } from "react";
import TituloDaPagina from "../../components/tituloDaPagina";
import {
  getFormulario,
  getListaProfissionais,
  statusContinuaProf
} from "../../store/actions/CRTActions";
import { connect } from "react-redux";
import CustomSkeleton from "../../components/customSkeleton";
import crEmpresa from "../../dadosPraSimulacao/crEmpresa.json";
import crProfissional from "../../dadosPraSimulacao/crProfissional.json";
import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  TextField,
  DialogActions,
  DialogContent,
  Snackbar,
  Alert,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import { setLoading } from "../../store/actions/CRTActions";
import { getCapitalSocial } from "../../store/actions/CRTActions";
import { useParams } from "react-router-dom";
import CRTexto from "../../components/crt/crtTexto";
import { apiProfissional } from "../../services/apiProfissional";
import MensagemFeedback from "../../components/mensagemFeedback";
const ProfSolic = ({
  loading,
  capitalSocial,
  auth,
  formularioInicial,
  lista,
}) => {
  const { idEmp, solic } = useParams();
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [continua, setContinua] = useState([]);

  useEffect(() => {
    (async () => {
      setSkeletonLoading(true);
      let res = await getCapitalSocial(auth.tipoUsuario);
      let res3 = await getListaProfissionais(idEmp, auth.tipoUsuario);
      let res2 = await getFormulario(idEmp, auth.tipoUsuario);
      let res4 = await statusContinuaProf(auth.idusuario, idEmp, solic);
      setContinua(res4)
      setSkeletonLoading(false);
    })();
  }, []);

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [dadosEnviados, setDadosEnviados] = useState(false);
  const [msgDadosEnviados, setMsgDadosEnviados] = useState('');
  const [erroAoSalvar, setErroAoSalvar] = useState(false);
  const [msgErro, setMsgErro] = useState('');
  const [txtObs, setTxtObs] = useState('');

  const enviarNegativa = () => {
    setLoading(true);
    try{
      const res = apiProfissional.post("/observa", {
        idProf: auth.idusuario,
        idEmp,
        solicitacaoId: solic,
        txtObs
      });

      if(res){
        setDadosEnviados(true);
        setMsgDadosEnviados("Enviado email para a empresa com os motivos do RT ter rejeitado CR")
        setTimeout(() => {
          setDadosEnviados(false);
          setLoading(false);
          navigate("/histSolic");
        }, 5000);
      }else{
        setErroAoSalvar(true);
        setMsgErro("Erro ao salvar CR")
        setTimeout(() => {
          setErroAoSalvar(false);
          setLoading(false);
          navigate("/histSolic");
        }, 2000);
      }
    }catch (error) {
      console.log("error ", error);
    }
    setOpenModal(false);
  };

  const confirmarAlteracoes = async () => {
    setLoading(true);
    try {
      const res = await apiProfissional.post("/visto", {
        idProf: auth.idusuario,
        idEmp,
        solicitacaoId: solic,
      });

      if (res.status === 200) {
        setDadosEnviados(true);
        setMsgDadosEnviados(res.data.msg)
        setTimeout(() => {
          setDadosEnviados(false);
          setLoading(false);
          navigate("/histSolic");
        }, 5000);
      } else {
        setErroAoSalvar(true);
        setMsgErro("Erro ao salvar CR")
        setTimeout(() => {
          setErroAoSalvar(false);
          setLoading(false);
          navigate("/histSolic");
        }, 2000);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mb: 2,
      }}
    >
      <TituloDaPagina titulo="Alteração de CR" />
      {skeletonLoading ? (
        <CustomSkeleton />
      ) : (
        <>
          <MensagemFeedback
            open={dadosEnviados}
            msg= {msgDadosEnviados}
            color="success"
          />
          <MensagemFeedback
            open={erroAoSalvar}
            msg={msgErro}
            color="error"
          />
          {loading ? (
            <Loading status={true} />
          ) : (
            <>
              <CRTexto
                dados={formularioInicial}
                prof={lista}
                capitalSocial={capitalSocial}
              />
              
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                {continua.fluxo ? 
                  <>
                  <Typography variant="h6">
                    Você confirma as alterações de CR?
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setOpenModal(true)}
                    >
                      Não
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => confirmarAlteracoes()}
                    >
                      Sim
                    </Button>
                  </Box>
                  </>
                : <>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate('/histSolic')}
                    >
                      Voltar
                    </Button>
                  </> 
                
                }
              </Box>
            </>
          )}
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            PaperProps={{
              sx: {
                width: "50%",
              },
            }}
          >
            <DialogTitle>Recusar CR {solic}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Por favor, liste os motivos para recusa na CR {solic}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                type="text"
                fullWidth
                variant="outlined"
                label="Descreva os motivos"
                multiline
                onChange={(e) => setTxtObs(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenModal(false)}
                variant="outlined"
                color="error"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => enviarNegativa()}
                variant="outlined"
                color="primary"
              >
                Enviar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  formularioInicial: state.CRTReducer.formularioInicial,
  formularioEditado: state.CRTReducer.formularioEditado,
  lista: state.CRTReducer.listaProfissionais,
  auth: state.AutenticacaoReducer.autenticacao,
  formularioValido: state.CRTReducer.formularioValido,
  loading: state.CRTReducer.loading,
  capitalSocial: state.CRTReducer.capitalSocial,
});
export default connect(mapStateToProps)(ProfSolic);
