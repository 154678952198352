import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { Paper } from "@mui/material";
import { useEffect } from "react";

import { connect } from "react-redux";
import { rotulosEmpresa } from "../../rotulos/empresa";
import listasubtipo from '../../rotulos/subtipo.json';

const TabelaHistoricoEmpresa = ({dados, capitalSocial, tipoDeEstabelecimento}) => {
	
  const converterParaTexto = (key,value) => {
    switch (key){
      case 'capitalsocial_idcapitalsocial':
      let capital = capitalSocial.find(element => element.idcapitalsocial === value)
        if(capital === undefined) return ''
        return capital.texto
      case 'idtipo':
        let estabelecimento = tipoDeEstabelecimento.find(element => element.idtipo === value )
        if(estabelecimento === undefined) return ''
        return estabelecimento.tipo
      case 'idsubtipo':
        let subtipo = listasubtipo.find(element => element.idsubtipo === value)
        if(subtipo === undefined) return ''
        return subtipo.textosubtipo
      default:
        return value
    }
  }
  

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
      component={Paper}
    >
      <Typography variant="h5">Alterações solicitadas - Dados da empresa</Typography>
      <Table sx={{border:'solid gray 1px'}} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Pedido</TableCell>
            <TableCell>Antes</TableCell>
            <TableCell>Depois</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dados.length > 0 && dados.map(h => (
              <TableRow>
              <TableCell>{rotulosEmpresa[h.altera]}</TableCell>
              <TableCell>{converterParaTexto(h.altera,h.de)}</TableCell>
              <TableCell>{converterParaTexto(h.altera,h.para)}</TableCell>
            </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </Box>
  );
};



const mapStateToProps = (state) => ({
  capitalSocial: state.CRTReducer.capitalSocial,
  tipoDeEstabelecimento: state.CRTReducer.tipoDeEstabelecimento
});
export default connect(mapStateToProps)(TabelaHistoricoEmpresa);
