import { connect } from "react-redux";
import { Box } from "@mui/system";
import { Divider, Grid, ListItem, Link, Alert } from "@mui/material";
import { Typography } from "@mui/material";
import TituloDaPagina from "../../../components/tituloDaPagina";
import { useEffect, useState } from "react";
import { apiEmpresa } from "../../../services/apiEmpresa";
import { setLoading } from "../../../store/actions/CRTActions";
import Loading from "../../../components/loading";
import {useNavigate, useOutletContext} from 'react-router-dom';
import ListaAnexos from "../../../components/crt/anexosCRT/ListaAnexos";

const Anexos = ({
  solicitacaoAberta,
  auth,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const {setCompleted, completed} = useOutletContext();
  const [anexosPendentes, setAnexosPendentes] = useState([]);
  const [haAnexos, setHaAnexos] = useState(true);
  const {handleActive} = useOutletContext();
  const [aindaNaoHaAnexo, setAindaNaoHaAnexo] = useState(false);
  const [anexosEmpresa, setAnexosEmpresa] = useState([]);
  const [anexosProfissionais, setAnexosProfissionais] = useState([]);

  useEffect(() => {
    handleActive(1);
    setLoading(true);
    // Se houver solicitação aberta e os anexos já houverem sido enviados, lista-se eles.
      (async () => {
        if(solicitacaoAberta.solic === ""){
          setAindaNaoHaAnexo(true)
        }
        else{
          try {
            const res = await apiEmpresa.get("/anexosPendentes", {
              params: {
                idEmp: auth.idusuario,
                solicitacaoId:solicitacaoAberta.solic,
              },
            });
          
            if(res.status === 204 || res.data.msg !== undefined){
              setHaAnexos(false);
              let prevState = completed;
              prevState[1] = true;
              setCompleted(prevState);
            }
            else{
              setHaAnexos(true);
              setAnexosPendentes(res.data);
              setAnexosEmpresa(res.data.filter(e => e.nome === "Empresa")[0] || []);
              let ap = res.data.filter(e => e.nome !=="Empresa")
              if(ap.length){
                ap.map(p => {
                  p.anexoSelecionado = {
                  id: null,
                  tipo: null,
                  titulo: null,
                  anexo: null,
                  arquivo_de: p.idprofissional
                }
              })
              }
              setAnexosProfissionais(ap);
            }
            setAindaNaoHaAnexo(false);
          } catch (error) {
            setAindaNaoHaAnexo(true);
          }
        }
        setLoading(false);
      })();
    
  }, []);

  return (
    <>
      {loading ? (
        <Loading status={loading} />
      ) : (
        <>
          <Box sx={{ p: 3 }}>
            {anexosPendentes.length > 0 && (
              <ListaAnexos
              anexos={anexosPendentes} 
              anexosEmpresa={anexosEmpresa} 
              anexosProfissionais={anexosProfissionais}
              />
            )}
          </Box>
          {aindaNaoHaAnexo && (<Alert severity='warning'>Complete o cadastro antes de anexar os arquivos</Alert>)}
          {!haAnexos && (
            <Box>
            <Alert severity='warning'>Nenhum anexo é necessário</Alert>
            {solicitacaoAberta.status ==='7' && (
              <Link 
                onClick={()=> navigate(`/empSolic/pagamento/${solicitacaoAberta.solic}`)}
                sx={{'&:hover':{cursor:'pointer'}}}
              >
                Ir para pagamento
              </Link>
            )}
          </Box>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
  formularioIncial: state.CRTReducer.formularioInicial,
  formularioTemporario: state.CRTReducer.formularioTemporario,
  solicitacaoAberta: state.CRTReducer.solicitacaoAberta,
});
export default connect(mapStateToProps)(Anexos);
