// react router e redux
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// formik
import {
  useFormik,
  FieldArray,
  ErrorMessage,
  FormikProvider,
  getIn,
} from "formik";

// material ui
import {
  Box,
  Grid,
  Button,
  TextField,
  Paper,
  Divider,
  Toolbar,
  Dialog,
  Alert,
  LinearProgress,
  MenuItem,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

// componenentes próprios
import Loading from "../../loading";
import MensagemFeedback from "../../mensagemFeedback";
import ModalImg from "../../modalImg";
import ModalImgLink from "../../modalImgLink";
import { apiEmpresa } from "../../../services/apiEmpresa";
import validar from "./validacaoFormulario";

const ListaAnexos = ({
  auth,
  solicitacaoAberta,
  anexosEmpresa,
  anexosProfissionais,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [erroAoEnviarAnexos, setErroAoEnviarAnexos] = useState(false);
  const [anexosEnviados, setAnexosEnviados] = useState(false);
  const [open, setOpen] = useState({});
  const [openEdit, setOpenEdit] = useState({});
  const [profissionaisPendentes, setProfissionaisPendentes] = useState({});
  const [profissionaisParaValidacao, setProfissionaisParaValidacao] = useState([]);
  const [alertAnexoEmpresa, setAlertAnexoEmpresa] = useState([]); 
  const [empresaArquivoAlterado, setEmpresaArquivoAlterado] = useState({
    0 : false,
    1 : false,
    2 : false
  });
  const [alertAnexoProfissional, setAlertAnexoProfissional] = useState([]); 
  const [profissionalArquivoAlterado, setProfissionalArquivoAlterado] =
    useState({
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
    });

  const [erroEncontrado, setErroEncontrado] = useState(false);

  const validationSchema = (solicitacaoAberta.status !== "5" ? validar({ empresa: anexosEmpresa.data, profissionais: anexosProfissionais }) : undefined)
  const formik = useFormik({
    initialValues: {
      empresa: anexosEmpresa.data,
      profissionais: anexosProfissionais,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (values.empresa?.length) {
        let res = await enviarEmpresa(values.empresa);
      }

      values.profissionais = values.profissionais.filter(
        (element) => element !== undefined
      );

      let idsProfissionais = [];
      values.profissionais.map((element) => {
        idsProfissionais.push(element.idprofissional);
      });

      if (idsProfissionais.length) {
        let res = await enviarProfissionais(
          values.profissionais,
          idsProfissionais
        );
      }

      setLoading(false);
      navigate(`/empSolic/pagamento/${solicitacaoAberta.solic}`);
    },
  });

  async function enviarEmpresa(values) {
    for (let index = 0; index < values.length; index++) {
      const value = values[index];
      if (value.anexo?.name !== undefined) {
        value.arquivo_de = anexosEmpresa.idempresa;
        const res = await enviarParaApi(value);
        if (!res) {
          setErroEncontrado(true);
        }
      }
    }

    return;
  }

  async function enviarProfissionais(values, profissionais) {
    for (let i = 0; i < profissionais.length; i++) {
      if (values[i]?.anexoSelecionado.anexo !== null) {
        if (values[i]?.anexoSelecionado.tipo === null) {
          values[i].anexoSelecionado.tipo = values[i].data[0].id;
        }
        const res = await enviarParaApi(values[i].anexoSelecionado);
        if (!res) setErroEncontrado(true);
      }
    }
    return;
  }

  const enviarParaApi = async (value) => {
    const { anexo, id, arquivo_de } = value;

    let formData = new FormData();
    formData.append("anexo", anexo);
    formData.append("tipo_arquivo", id ? id : value.tipo);
    formData.append("solicitacaoid", solicitacaoAberta.solic);
    formData.append("usr_upload", auth.idusuario);
    formData.append("tipo_usr", auth.tipoUsuario === "empresa" ? 1 : 3);
    formData.append("arquivo_de", arquivo_de);

    try {
      const res = await apiEmpresa.post("/attach", formData, {
        headers: {
          ContentType: "multipart/form-data",
        },
      });
      // catch não funciona com erro 500. Se houver um erro (code) retorna false; caso contrário, true.
      return res.code === undefined;
    } catch (error) {
      return false;
    }
  };

  const handleOpen = (e) => {
    setOpen({ [e]: !open[e] });
  };

  const cancelarEdicao = (index, e, tipo) => {

    if (tipo === "empresa") {
      formik.setFieldValue(
        `empresa[${index}].anexo`,
        formik.initialValues.empresa[index].anexo
      );
    } else if ("profissionais") {
      formik.setFieldValue(
        `profissionais[${index}].data[0].anexo`,
        formik.initialValues.profissionais[index].data[0].anexo
      );
    }

    handleOpenEdit(e);
    setLoading(false);
  };
  const handleOpenEdit = (e) => {

    setOpenEdit({ [e]: !openEdit[e] });
  };

  const deletarArquivoTemporario = (name) => {
    formik.setFieldValue(name, null);
  };

  const confirmArquivoAlterado = (tipoEstab, indexArquivo) => {
    if(tipoEstab == 'empresa'){
      let anexos = empresaArquivoAlterado;
      anexos[indexArquivo] = true;
      setEmpresaArquivoAlterado(anexos)
    }
    if(tipoEstab == 'profissionais'){
      let anexos = profissionalArquivoAlterado;
      anexos[indexArquivo] = true;
      setProfissionalArquivoAlterado(anexos)
    }
    handleOpenEdit(`ap-emp-${indexArquivo}`)
  }

  useEffect(() => {
    let profPendentes = {};
    anexosProfissionais
      .filter((element) =>
        element.data.every(
          (anexo) => anexo.anexo === null || anexo.status !== null
        )
      )
      .map((e) => {
        profPendentes = {
          ...profPendentes,
          [e.idprofissional]: e,
        };
      });

    setProfissionaisPendentes(profPendentes);
  }, []);

  const enviar = () => {
    setLoading(true);
    let haErroEmpresa = formik.errors.hasOwnProperty("empresa");
    let haErroProfissionais = formik.errors.hasOwnProperty("profissionais");

    /*
    if (haErroEmpresa || haErroProfissionais) {
      haErro();
      formik.handleSubmit();
    } else {
      formik.handleSubmit();
    }*/
    formik.handleSubmit();

    setLoading(false);
  };

  const haErro = () => {
    setErroAoEnviarAnexos(true);
    setTimeout(() => {
      setErroAoEnviarAnexos(false);
      setLoading(false);
    }, 5000);
  };

  const alteraAnexoProfissional = async (anexo, index, a) => {
    //Altera o profissional e adiciona o mesmo tipo de anexo anterior novamente no form
    await formik.setFieldValue(`profissionais[${index}].anexoSelecionado.anexo`,anexo);
    await formik.setFieldValue(`profissionais[${index}].anexoSelecionado.id`,a.id);
  }

  return (
    <>
      <MensagemFeedback
        open={erroAoEnviarAnexos}
        color="error"
        msg="Erro ao enviar anexos! Verifique o tamanho e os formatos suportados."
      />
      <MensagemFeedback
        open={anexosEnviados}
        color="sucess"
        msg="Anexos enviados com sucesso!"
      />
      <Loading status={loading} />
      <Typography variant="h6">Anexe os documentos</Typography>
      <Box>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            {anexosEmpresa &&
              anexosEmpresa.data?.length > 0 &&
              anexosEmpresa.data?.map((a, index) => (
                <Box
                  component={Paper}
                  variant="otlined"
                  sx={{ mb: 2, p: 2 }}
                  key={a.titulo}
                >
                  {a.anexo !== null ? (
                    <>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Typography variant="subtitle" color="primary.main">
                          {a.titulo}
                        </Typography>
                        <IconButton>
                          <VisibilityIcon
                            onClick={() => handleOpen(`ap-emp-${index}`)}
                          />
                        </IconButton>
                        {a.status && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  handleOpenEdit(`ap-emp-${index}`)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                              {!empresaArquivoAlterado[index] ? (
                                <Alert severity="warning">
                                  Documento {a.status}
                                </Alert>
                              ) : (
                                <Alert severity="success">
                                  Arquivo pronto para enviar
                                </Alert>
                              )}
                            </Box>
                            <Dialog
                              open={openEdit[`ap-emp-${index}`]}
                              onClose={() => handleOpenEdit(`ap-emp-${index}`)}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                              scroll="paper"
                              fullWidth
                            >
                              <DialogTitle>Anexe o novo documento</DialogTitle>
                              <DialogContent>
                                <Button
                                  variant="contained"
                                  component="label"
                                  color="primary"
                                >
                                  <AttachFileIcon /> Anexar arquivo
                                  <TextField
                                    type="file"
                                    inputProps={{
                                      accept:
                                        "image/jpeg,image/png, image/bmp, application/pdf",
                                    }}
                                    values={formik.values.empresa?.[index].id}
                                    name={a.id}
                                    id={a.id}
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        `empresa[${index}].anexo`,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    sx={{ display: "none" }}
                                  />
                                </Button>
                                {typeof formik.values.empresa[index].anexo ===
                                "string" ? (
                                  <ModalImgLink
                                    link={`${process.env.REACT_APP_BASE_URL}/api4/${a.anexo}`}
                                    titulo={a.titulo}
                                    tipo={a.tipo}
                                    handleClose={() =>
                                      cancelarEdicao(
                                        index,
                                        `ap-emp-${index}`,
                                        "empresa"
                                      )
                                    }
                                  />
                                ) : (
                                  <ModalImg
                                    file={formik.values.empresa[index].anexo}
                                    titulo={a.titulo}
                                    handleClose={() =>
                                      cancelarEdicao(
                                        index,
                                        `ap-emp-${index}`,
                                        "empresa"
                                      )
                                    }
                                  />
                                )}
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() =>
                                    cancelarEdicao(
                                      index,
                                      `ap-emp-${index}`,
                                      "empresa"
                                    )
                                  }
                                >
                                  Cancelar
                                </Button>
                                <Button
                                  onClick={() =>
                                    confirmArquivoAlterado(
                                      `empresa`,
                                      `${index}`
                                    )
                                  }
                                  disabled={formik.values.empresa[index] === formik.initialValues.empresa[index]}
                                >
                                  Ok
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
                      </Box>
                      <Dialog
                        open={open[`ap-emp-${index}`]}
                        onClose={() => handleOpen(`ap-emp-${index}`)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        scroll="paper"
                        fullWidth
                      >
                        <ModalImgLink
                          link={`${process.env.REACT_APP_BASE_URL}/api4/${a.anexo}`}
                          titulo={a.titulo}
                          tipo={a.tipo}
                          handleClose={() => handleOpen(`ap-emp-${index}`)}
                        />
                      </Dialog>
                      <Divider />
                    </>
                  ) : (
                    <Grid container item direction="column" sx={{ gap: 1 }}>
                      <Grid item xs={12}>
                        <Typography variant="h6">{a.titulo}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid
                        container
                        item
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        rowSpacing={1}
                      >
                        <Grid item xs={12} md={4}>
                          <Button
                            variant="contained"
                            component="label"
                            color="primary"
                          >
                            <AttachFileIcon />{" "}
                            {a.anexo === null
                              ? "Anexar arquivo"
                              : "Trocar arquivo"}
                            <TextField
                              type="file"
                              inputProps={{
                                accept:
                                  "image/jpeg,image/png, image/bmp, application/pdf",
                              }}
                              values={formik.values.empresa?.[index].id}
                              name={a.id}
                              id={a.id}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  `empresa[${index}].anexo`,
                                  event.currentTarget.files[0]
                                );
                              }}
                              sx={{ display: "none" }}
                            />
                          </Button>
                          <Box>
                            <small>
                              Tamanho máximo por arquivo 20MB, nos formatos pdf,
                              jpeg e png
                            </small>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "primary.main",
                                "&:hover": {
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={() => handleOpen(`empresa-${index}`)}
                            >
                              {formik.values.empresa[index].anexo?.name}
                            </Typography>
                            {formik.values.empresa[index].anexo?.name !==
                              undefined && (
                              <IconButton
                                onClick={() =>
                                  deletarArquivoTemporario(
                                    `empresa[${index}].anexo`
                                  )
                                }
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            )}
                          </Box>
                          <Dialog
                            open={open[`empresa-${index}`]}
                            onClose={() => handleOpen(`empresa-${index}`)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            scroll="paper"
                            fullWidth
                          >
                            <ModalImg
                              file={formik.values.empresa[index].anexo}
                              titulo={a.titulo}
                              handleClose={() => handleOpen(`empresa-${index}`)}
                            />
                          </Dialog>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          {formik.errors.empresa?.[index]?.anexo && (
                            <Alert severity="error">
                              {formik.errors.empresa[index].anexo}
                            </Alert>
                          )}
                          {formik.errors.empresa?.[index] === undefined &&
                          formik.values.empresa[index].anexo !== null ? (
                            <Alert severity="success">
                              Arquivo pronto para enviar
                            </Alert>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              ))}
            {anexosProfissionais &&
              anexosProfissionais.length > 0 &&
              anexosProfissionais.map((prof, index) => (
                <Box
                  component={Paper}
                  variant="outlined"
                  sx={{
                    mb: 2,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                  key={prof.nome}
                >
                  <Typography variant="h6">{prof.nome}</Typography>
                  {formik.values.profissionais[index].data.some(
                    (element) => element.anexo !== null
                  ) ? (
                    <>
                      {prof.data
                        .filter((anexo) => anexo.anexo !== null)
                        .map((a, idx) => (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="subtitle"
                                color="primary.main"
                              >
                                {a.titulo}
                              </Typography>
                              <IconButton>
                                <VisibilityIcon
                                  onClick={() =>
                                    handleOpen(`ap-prof-${index}-${a.id}`)
                                  }
                                />
                              </IconButton>
                              {a.status && (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleOpenEdit(
                                          `ap-prof-${index}-${a.id}`
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    {!profissionalArquivoAlterado[index] ? (
                                      <Alert severity="warning">
                                        Documento {a.status}
                                      </Alert>
                                    ) : (
                                      <Alert severity="success">
                                        Arquivo pronto para enviar
                                      </Alert>
                                    )}
                                  </Box>
                                  <Dialog
                                    open={openEdit[`ap-prof-${index}-${a.id}`]}
                                    onClose={() =>
                                      handleOpenEdit(`ap-prof-${index}-${a.id}`)
                                    }
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    scroll="paper"
                                    fullWidth
                                  >
                                    <DialogTitle>
                                      Anexe o novo documento
                                    </DialogTitle>
                                    <DialogContent>
                                      <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                      >
                                        <AttachFileIcon /> Anexar arquivo
                                        <TextField
                                          type="file"
                                          inputProps={{
                                            accept:
                                              "image/jpeg,image/png, image/bmp, application/pdf",
                                          }}
                                          values={
                                            formik.values.profissionais?.[index]
                                              .id
                                          }
                                          name={a.id}
                                          id={a.id}
                                          onChange={(event) => {alteraAnexoProfissional(event.currentTarget.files[0],index,a)}}
                                          sx={{ display: "none" }}
                                        />
                                      </Button>
                                      {typeof formik.values.profissionais[index]
                                        .data[0].anexo === "string" &&
                                      formik.values.profissionais[index]
                                        .anexoSelecionado.anexo === null ? (
                                        <ModalImgLink
                                          link={`${process.env.REACT_APP_BASE_URL}/api4/${a.anexo}`}
                                          titulo={a.titulo}
                                          tipo={a.tipo}
                                          handleClose={() =>
                                            cancelarEdicao(
                                              index,
                                              `ap-prof-${index}-${a.id}`,
                                              "profissionais"
                                            )
                                          }
                                        />
                                      ) : (
                                        <ModalImg
                                          file={
                                            formik.values.profissionais[index]
                                              .anexoSelecionado.anexo
                                          }
                                          titulo={a.titulo}
                                          handleClose={() =>
                                            cancelarEdicao(
                                              index,
                                              `ap-prof-${index}-${a.id}`,
                                              "profissionais"
                                            )
                                          }
                                        />
                                      )}
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={() =>
                                          cancelarEdicao(
                                            index,
                                            `ap-prof-${index}-${a.id}`,
                                            "profissionais"
                                          )
                                        }
                                      >
                                        Cancelar
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          confirmArquivoAlterado(
                                            `profissionais`,
                                            `${index}`
                                          )
                                        }
                                        disabled={formik.values.profissionais[index] === formik.initialValues.profissionais[index]}
                                      >
                                        Ok
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </>
                              )}
                            </Box>
                            <Dialog
                              open={open[`ap-prof-${index}-${a.id}`]}
                              onClose={() =>
                                handleOpen(`ap-prof-${index}-${a.id}`)
                              }
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                              scroll="paper"
                              fullWidth
                            >
                              <ModalImgLink
                                link={`${process.env.REACT_APP_BASE_URL}/api4/${a.anexo}`}
                                titulo={a.titulo}
                                tipo={a.tipo}
                                handleClose={() =>
                                  handleOpen(`ap-prof-${index}-${a.id}`)
                                }
                              />
                            </Dialog>
                            <Divider />
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      <Grid container item direction="column">
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            select
                            label="Selecione o documento de vínculo do profissional"
                            margin="normal"
                            fullWidth
                            // sx={{width:350}}
                            id={`profissionais[${index}].anexoSelecionado.id`}
                            name={`profissionais[${index}].anexoSelecionado.id`}
                            value={
                              formik.values.profissionais[index]
                                ?.anexoSelecionado?.id
                            }
                            onChange={formik.handleChange}
                            // InputLabelProps={{ shrink: true }}
                            error={
                              formik.touched.profissionais?.[
                                prof.idprofissional
                              ]?.anexoSelecionado?.id &&
                              Boolean(
                                formik.errors.profissionais?.[
                                  prof.idprofissional
                                ]?.anexoSelecionado.id
                              )
                            }
                            helperText={
                              formik.touched.profissionais?.[
                                prof.idprofissional
                              ]?.anexoSelecionado?.id &&
                              formik.errors.profissionais?.[prof.idprofissional]
                                ?.anexoSelecionado?.id
                            }
                          >
                            <MenuItem value=""></MenuItem>
                            {prof.data.map((ap, index) => (
                              <MenuItem value={ap.id}>{ap.titulo}</MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid
                          container
                          item
                          direction="row"
                          alignItems="flex-end"
                          justifyContent="space-between"
                          rowSpacing={1}
                        >
                          <Grid item xs={12} md={4}>
                            <Button
                              variant="contained"
                              component="label"
                              color="primary"
                            >
                              <AttachFileIcon /> Anexar arquivo
                              <TextField
                                type="file"
                                inputProps={{
                                  accept:
                                    "image/jpeg,image/png, image/bmp, application/pdf",
                                }}
                                values={
                                  formik.values.profissionais[index]
                                    ?.anexoSelecionado?.anexo
                                }
                                name={`profissionais[${index}].anexoSelecionado.anexo`}
                                id={`profissionais[${index}].anexoSelecionado.anexo`}
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    `profissionais[${index}].anexoSelecionado.anexo`,
                                    event.currentTarget.files[0]
                                  );
                                }}
                                sx={{ display: "none" }}
                              />
                            </Button>
                            <Box>
                              <small>
                                Tamanho máximo por arquivo 20MB, nos formatos
                                pdf, jpeg e png
                              </small>
                            </Box>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "primary.main",
                                  "&:hover": {
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  },
                                }}
                                onClick={() =>
                                  handleOpen(`profissional-${index}`)
                                }
                              >
                                {
                                  formik.values.profissionais[index]
                                    ?.anexoSelecionado?.anexo?.name
                                }
                              </Typography>
                              {formik.values.profissionais[index]
                                ?.anexoSelecionado?.anexo?.name !==
                                undefined && (
                                <IconButton
                                  onClick={() =>
                                    deletarArquivoTemporario(
                                      `profissionais[${index}].anexoSelecionado.anexo`
                                    )
                                  }
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              )}
                            </Box>
                            <Dialog
                              open={open[`profissional-${index}`]}
                              onClose={() =>
                                handleOpen(`profissional-${index}`)
                              }
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                              scroll="paper"
                              fullWidth
                            >
                              <ModalImg
                                file={
                                  formik.values.profissionais[index]
                                    ?.anexoSelecionado?.anexo
                                }
                                titulo={"a.titulo"}
                                handleClose={() =>
                                  handleOpen(`profissional-${index}`)
                                }
                              />
                            </Dialog>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            {/*erro em caso de tipo do documento de vínculo do profissional não selecionado*/}
                            {formik.errors.profissionais?.[index]
                              ?.anexoSelecionado?.id && (
                              <Alert severity="error">
                                {
                                  formik.errors.profissionais?.[index]
                                    ?.anexoSelecionado?.id
                                }
                              </Alert>
                            )}
                            {/*erro em caso de anexo não selecionado*/}
                            {formik.errors.profissionais?.[index]
                              ?.anexoSelecionado?.anexo && (
                              <Alert severity="error">
                                {
                                  formik.errors.profissionais?.[index]
                                    ?.anexoSelecionado?.anexo
                                }
                              </Alert>
                            )}
                            {formik.errors.profissionais?.[index]
                              ?.anexoSelecionado?.anexo === undefined &&
                            formik.values.profissionais?.[index]
                              ?.anexoSelecionado !== undefined &&
                            formik.values.profissionais[index].anexoSelecionado
                              .anexo !== null &&
                            formik.errors.profissionais?.[index]
                              ?.anexoSelecionado?.id === undefined ? (
                              <Alert severity="success">
                                Arquivo pronto para enviar
                              </Alert>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              ))}
            <Box
              sx={{
                display: "flex",
                gap: 5,
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Button
                type="button"
                variant="outlined"
                color="error"
                onClick={() => navigate("/empSolic/dados")}
              >
                Voltar
              </Button>
              <LoadingButton
                onClick={enviar}
                variant="outlined"
                color="primary"
                disabled={ !formik.dirty }
                // loading={loading}
              >
                Avançar
              </LoadingButton>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
  solicitacao: state.CRTReducer.solicitacao,
  solicitacaoAberta: state.CRTReducer.solicitacaoAberta,
});
export default connect(mapStateToProps)(ListaAnexos);
