const INITIAL_STATE = {
      autenticacao: {
      token: '',
      estaLogado: false,
      tipoUsuario:'',
      idusuario:''
    },
    nome:'',
    endereco:'',
    usuario: {
      menu:[]
    },
    sessaoExpirada: false,
    listaFiliais:null
  }
  
  function AutenticacaoReducer(state = INITIAL_STATE, action) {
    if (action.type === 'SET_LOGIN') {
        return {
          ...state,
          autenticacao: {
            token: action.token,
            estaLogado: action.estaLogado,
            tipoUsuario: action.tipoUsuario,
            idusuario: action.idusuario,
          },
          nome: action.nome,
          endereco: action.endereco || ''
        }      
    }
    if(action.type === 'SET_LOGOUT'){
     
      return INITIAL_STATE
    }

    if(action.type ==='GET_MENU'){
      return {
        ...state,
        usuario: {
          menu: action.menu
        }
      }
    }
    
    if(action.type === 'SET_SESSAO_EXPIRADA'){
      return {
        ...state,
        sessaoExpirada: action.payload
      }
    }

    if(action.type === 'SET_USUARIO'){
     const autenticacao = state.autenticacao;
     autenticacao.idusuario = action.idusuario;
     const usuario = state.usuario;
     const nome = action.nome
     const endereco = action.endereco
      return {
        ...state,
        autenticacao,
        nome,
        endereco
        
      }
    }

    if(action.type === 'SET_FILIAIS'){
      return {
        ...state,
        listaFiliais: action.payload
      }
    }
    return state
  }
  
  export default AutenticacaoReducer;