import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Box} from "@mui/material";
import Loading from '../loading';

const Redirecionar = ({url, time, text, children}) => {
    const navigate = useNavigate();
    const [segundos, setSegundos] = useState(time);
    const [loading, setLoading] = useState(false);

   
    useEffect(() => {
        if(segundos <= 2) setLoading(true);
        if(segundos === 0) {
           setLoading(false);
            if(url !== undefined) navigate(url)
        }
        else{
            setTimeout(()=> setSegundos(prevState => prevState - 1),1000)
        }
    },[segundos])

    return (
        <Box>
            <Loading status={loading}/>
            <Typography>{text ? text : ''}</Typography>
            {children}
        </Box>
    )
}

export default Redirecionar;