import { legacy_createStore as createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import {persistStore, persistReducer} from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
import {rootReducer} from './reducers';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

// ver redux-persist-expire: https://www.npmjs.com/package/redux-persist-expire

const persistConfig = {
    key:'root',
    storage:sessionStorage,
    stateReconciler: hardSet
    
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, composeWithDevTools());
const persistor = persistStore(store);

export {store, persistor};