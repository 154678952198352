import { Tooltip, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { setLoading } from "../../store/actions/CRTActions";
import { apiEmpresa } from "../../services/apiEmpresa";
import { getSolicitacoes } from "../../store/actions/CRTActions";
import { connect } from "react-redux";
import { useEffect } from "react";
import MensagemFeedback from "../mensagemFeedback";

const DeletarSolic = ({auth, status, idEmp, solicitacao_id, loading, setSolicitacoes }) => {
  const navigate = useNavigate();
  const [openDialogDelete, setOpenDialogDelete] = useState({});
	const [deletarSolicMsg, setDeletarSolicMsg] = useState({
    open:false,
    msg:'',
    color:''
  })

  const podeDeletar = (status) => {
    let statusPodeEditar = [
      "Aberta",
      "Aguardando Pagamento",
      "Pendente",
      "Correção de dados realizada",
      "Aguardando Preenchimento de Anexos",
      "Aguardando posição do Profissional/RT"
    ];

    return statusPodeEditar.find((e) => e === status) !== undefined;
  };

  const handleOpenDialogDelete = (e) => {
    setOpenDialogDelete({ [e]: !openDialogDelete[e] });
  };

	const deletarSolicitacao = async (idEmp, solicitacaoId) => {
    setLoading(true);
    const res = await apiEmpresa.post('/remSolic',{idEmp,solicitacaoId});
    if(res.status === 200){
      setDeletarSolicMsg({
        open:true,
        msg: `Solicitação ${solicitacaoId} excluída com sucesso`,
        color:'success'
      })
    }
    else{
      setDeletarSolicMsg({
        open:true,
        msg: res.data?.msg,
        color:'error'
      })
    }
    handleOpenDialogDelete(solicitacaoId);
    const atualizaSolicitacoes = await getSolicitacoes(auth.idusuario, auth.tipoUsuario);
    if(atualizaSolicitacoes) setSolicitacoes(atualizaSolicitacoes);
    setLoading(false);
    setTimeout(()=>{
      setDeletarSolicMsg({
        open:false,
        msg:'',
        color:''
      });
    },2000)
  }

  return (
    <>
      <Tooltip
        title={
          podeDeletar(status)
            ? "Excluir alteração de CR"
            : `Não é possível excluir solicitação ${status?.toLowerCase()}`
        }
        arrow
        placement="top"
      >
        <span>
          <IconButton disabled={!podeDeletar(status)}>
            <DeleteIcon
              color={podeDeletar(status) ? "error" : "lightgray"}
              onClick={() => handleOpenDialogDelete(solicitacao_id)}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        open={openDialogDelete[solicitacao_id]}
        onClose={() => handleOpenDialogDelete(solicitacao_id)}
      >
        <DialogTitle>Excluir solicitação</DialogTitle>
        <DialogContent>
          <Typography variant="body" paragraph>
            Você tem certeza que deseja excluir a solicitação{" "}
            {solicitacao_id} ?
          </Typography>
          <Typography variant="body" paragraph>
            Essa ação não poderá ser revertida.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: "center" }}>
          <Button
            onClick={() => handleOpenDialogDelete(solicitacao_id)}
            color="error"
            variant="outlined"
          >
            Não
          </Button>
          <Button
            onClick={() =>
              deletarSolicitacao(idEmp, solicitacao_id)
            }
            color="primary"
            variant="outlined"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <MensagemFeedback open={deletarSolicMsg.open} msg={deletarSolicMsg.msg} color={deletarSolicMsg.color}  />
    </>
  );
};


const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
  loading: state.CRTReducer.loading
});
export default connect(mapStateToProps)(DeletarSolic);

