import {
    Button,
    DialogContent,
    DialogTitle,
    Box
  } from "@mui/material";

  const SessaoExpirada = ({acao}) => {
      
    return (
      <>
        <DialogTitle>Sessão Expirada</DialogTitle>
        <DialogContent>
          <Box>
          <Button 
            variant='contained'
            fullWidth
            onClick={()=>acao()}
          >
            Entrar
          </Button>
          </Box>
        </DialogContent>
      </>
    );
  };
  
  export default SessaoExpirada;
  