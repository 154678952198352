import axios from 'axios';

export const apiFiles = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api4/empresa/attach`,
  headers:{
    "Content-Type": 'multipart/form-data'
  },
  mode: 'no-cors'
  
})
