export const rotulosEmpresa = {
    idempresa: "ideempresa",
    uf: "UF",
    nome: "Nome fantasia",
    razao: "Razão social",
    cnpj: "CNPJ",
    status_idstatus: "",
    empresa_categoria: "Categoria empresa",
    cep: "CEP",
    logradouro: "Logradouro",
    numero: "Número",
    complenum: "Complemento",
    bairro: "Bairro",
    cidade: "Cidade",
    idsetor: 0,
    uf_endereco: "UF",
    pais: "País",
    hr_seg: "Segunda-feira",
    hr_ter: "Terça-feira",
    hr_qua: "Quarta-feira",
    hr_qui: "Quinta-feira",
    hr_sex: "Sexta-feira",
    hr_sab: "Sábado",
    hr_dom: "Domingo",
    telefone: "Telefone" ,
    celular: "Celular" ,
    email1: "Email principal",
    email2: "Email secundário",
    site1:"Site" ,
    site2:"" ,
    facebook:"Facebook" ,
    linkedin: "",
    latitude: -27.5956932,
    longitude: -48.5443856,
    autoriza_correspondencia: "",
    autoriza_mailing: "",
    autoriza_sms: "",
    autoriza_redes_sociais: "",
    ativo: "",
    empresa_politica_idempresa_politica: "",
    capitalsocial_idcapitalsocial: "Capital social",
    categoria: 0,
    complemento: "",
    inscricao: "",
    matrfilial: "",
    distrito: "",
    dtinscratual: "",
    autoriza_dados_cadastrais: "",
    data_aceite_termos: "",
    perfil: "",
    isento: "",
    endereco_correspondencia:"" ,
    acesso_restrito: "",
    tipo_empresa: "",
    tipo_propriedade: "",
    sala_servico_farmaceutico: "",
    idempresa_fk: "",
    idsubtipo: "Subtipo de estabelecimento",
    subtipo: "Subtipo de estabelecimento",
    idtipo: "Tipo de estabelecimento",
    tipo: "Tipo de estabelecimento"
} 