import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import TabelaHistoricoProfissionalInterna from "./tabelaHistoricoProfissionalInterna";

const TabelaHistoricoProfissional = ({ dados }) => {
  const [open, setOpen] = useState({});
  const handleOpen = (e) => {
    setOpen({ [e]: !open[e] });
  };
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
      component={Paper}
    >
      <Typography variant="h5">
        Alterações solicitadas
      </Typography>
      <Table sx={{ border: "solid gray 1px" }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Situação</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dados.length > 0 &&
            dados.map((h, index) => (
              <>
                <TableRow>
                  <TableCell>{h.nome}</TableCell>
                  <TableCell>{h.situacao}</TableCell>
                  <TableCell>
                    {open[index] ? (
                      <ExpandLessIcon
                        onClick={() => handleOpen(index)}
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      />
                    ) : (
                      <ExpandMoreIcon
                        onClick={() => handleOpen(index)}
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      />
                    )}
                  </TableCell>
                </TableRow>

                
                  {open[index] && (              
									<TableRow>
                  <TableCell
                    sx={{ backgroundColor: "background.main" }}
                    colSpan={6}
                    align="center"
                  >
										<Box sx={{m:1}}>	
                    <TabelaHistoricoProfissionalInterna
                      dados={h.data}
                      ehNovo={h.situacao === "Novo"}
											/>
										</Box>
                  </TableCell>
									</TableRow>
                )}
              </>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TabelaHistoricoProfissional;
