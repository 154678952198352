import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const TituloDaPagina = ({titulo}) => {
  return (
    <Box>
      <Typography
        component="h1"
        variant="h4"
        gutterBottom
        align="center"
        color="primary.main"
        sx={{ fontWeight: "bold", my:'auto' }}
      >
       {titulo}
      </Typography>
    </Box>
  );
};

export default TituloDaPagina;
