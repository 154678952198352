import { Snackbar, Alert } from "@mui/material";
const MensagemFeedback = ({open, msg,color}) => {
    return(
        <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={1000}
        open={open}
      >
        <Alert severity={color}>
          {msg}
        </Alert>
      </Snackbar>
    )
}

export default MensagemFeedback;