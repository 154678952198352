import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import TituloDaPagina from "../components/tituloDaPagina";
import { useNavigate } from "react-router-dom";
import logo from '../assets/images/eCRF-horizontal.svg';
const NotFound = () => {
  const navigate = useNavigate();

	return (
    <Box
      sx={{
        backgroundColor: "ligthgray",
        height: "88vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 8,
      }}
    >
			<img src={logo} alt='logo do CRFRS' width={500}/>
      <TituloDaPagina titulo="Página não encontrada" />
      <Typography
        onClick={()=> navigate('/')}
				sx={{
          color: "primary.main",
          textDecoration: "underline",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        Ir para a página inicial
      </Typography>
    </Box>
  );
};

export default NotFound;
