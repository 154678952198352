import { useEffect, useState } from "react";
import TituloDaPagina from "../../../components/tituloDaPagina";
import Solicitacao from "../../../components/crt/solicitacao";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  Alert,
  IconButton,
  Link,
  Tooltip
} from "@mui/material";
import ForwardIcon from '@mui/icons-material/Forward';
import { TableBody } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import SearchIcon from '@mui/icons-material/Search';
import { getSolicitacoes } from "../../../store/actions/CRTActions";
import { setLoading } from "../../../store/actions/CRTActions";
import MensagemFeedback from "../../../components/mensagemFeedback";
import DeletarSolic from "../../../components/deletarSolic";
import DescriptionIcon from '@mui/icons-material/Description';

const HistoricoCRT = ({ auth, loading }) => {
  const navigate = useNavigate();

  const [solicitacoes, setSolicitacoes] = useState([]);

  useEffect(() => {
    setLoading(true);
    (async() => {
      const res = await getSolicitacoes(auth.idusuario, auth.tipoUsuario);
      if(res) {
        setSolicitacoes(res);
      }
      setTimeout(() => setLoading(false),500)
    })();
    
    ;
  },[])

  
  return (
    <>
      <TituloDaPagina titulo="Histórico de Solicitações" />
      <Box variant="otlined" sx={{ mb: 2, p: 2 }}>
        {loading && (
          <Skeleton
            variant="rectangular"
            height={"20vh"}
            animation="wave"
            sx={{ opacity: 0.3 }}
          />
        )}
        {!loading && solicitacoes.length > 0 && (
          <>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Data de abertura</TableCell>
                  <TableCell>Solicitação</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {solicitacoes.map((solic, index) => (
                  <TableRow >
                    <TableCell>
                      {moment(solic.dataAbertura,'YYYY-MM-DD HH:mm:ss').format("DD/MM/YYYY - HH:mm")}
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          color: "primary.main",
                          textDecoration: "underline",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        {" "}
                        
                        <Link onClick={()=> navigate(`/histSolic/${solic.solicitacao_id}`)}>{solic.solicitacao_id}</Link>
                      </Typography>
                    </TableCell>
                    <TableCell>{solic.tipo}</TableCell>
                    <TableCell>
                      <Box sx={{display:'flex', gap:1, alignItems:'center'}}>
                        <span>{solic.status}</span>
                        {solic.status === 'Aguardando Preenchimento de Anexos' ? (
                          <IconButton onClick={()=> navigate('/empSolic/anexos')}>
                            <ForwardIcon/>
                          </IconButton>
                        ): solic.status === 'Aguardando Pagamento' ? (
                          <IconButton onClick={()=> navigate(`/empSolic/pagamento/${solic.solicitacao_id}`)}>
                            <ForwardIcon/>
                          </IconButton>
                        ): null}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{display:'flex'}}>
                      <DeletarSolic
                        status={solic.status}
                        idEmp={auth.idusuario}
                        solicitacao_id={solic.solicitacao_id}
                        setSolicitacoes={setSolicitacoes}
                      />
                      <IconButton onClick={()=> navigate(`/histSolic/${solic.solicitacao_id}`)}>
                        <SearchIcon/>
                      </IconButton>
                      <a target="_blank" href={`https://farmasis.crfrs.org.br/_externo/atendimento-impressao.php?id=${solic.capa_protocolo}`}>
                        <Tooltip title='Visualizar capa do protocolo' arrow placement="top">
                        <IconButton>
                          <DescriptionIcon/>
                        </IconButton>
                        </Tooltip>
                      </a>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
        {!loading && solicitacoes.length === 0 && (
          <Alert severity="warning">Nenhuma solicitação encontrada</Alert>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
  loading: state.CRTReducer.loading
});
export default connect(mapStateToProps)(HistoricoCRT);
