import { useState } from "react";

// exemplo input radio - https://codesandbox.io/s/sleepy-tdd-784y8?file=/src/Radio.js:0-714
//https://codesandbox.io/s/t5br6?file=/src/Radio.tsx

// components Material
import { LoadingButton } from "@mui/lab";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { Button } from "@mui/material";

// components formik e yup
import { useFormik, Field } from "formik";
import * as yup from "yup";
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";

//components and images
import logo from "../../assets/images/logo.png";

import crfrs from '../../assets/images/eCRF-horizontal.svg'

// rotas

import { useNavigate } from "react-router-dom";

// redux
import { connect } from "react-redux";
import { getUser, handleLogin } from "../../store/actions/AutenticacaoActions";
import { Toolbar } from "@mui/material";

import { RadioGroup } from "formik-material-ui";

import { removeMaskCpfOrCnpj } from "../../helpers";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"CRF/RS - CNPJ: 93.026.771/0001-39 - Todos os direitos reservados.  © "}
      <Link color="inherit" href="https://www.crfrs.org.br/"></Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}



const validationSchema = yup.object().shape({
  tipoUsuario: yup
    .string()
    .required("Você deve escolher entra empresa e profissional"),
  cpf: yup.string("Insira seu CPF").when("tipoUsuario", {
    is: "profissional",
    then: yup
      .string()
      .test("usuario", "CPF deve conter 11 dígitos", (val) => {
        if(val === undefined) return true;
        return removeMaskCpfOrCnpj(val).length === 11;
      })
      .required("Usuário é um campo obrigatório"),
  }),
  cnpj: yup.string("Insira seu CNPJ").when("tipoUsuario", {
    is: "empresa",
    then: yup
      .string()
      .test("usuario", "CNPJ deve conter 14 dígitos", (val) => {
        if(val === undefined) return true;
        return removeMaskCpfOrCnpj(val).length === 14;
      })
      .required("Usuário é um campo obrigatório"),
  }),
  senha: yup
    .string("Insira sua senha")
    .min(5, "A senha deve ter no mínimo 5 caracteres")
    .required("Senha é um campo obrigatório"),
});

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);

  const formik = useFormik({
    initialValues: {
      cpf: "",
      cnpj: "",
      senha: "",
      tipoUsuario: "empresa",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
     
      if (values.tipoUsuario === "empresa") {
        values.cnpj = removeMaskCpfOrCnpj(values.cnpj);
        // delete values.cpf
      } else {
        values.cpf = removeMaskCpfOrCnpj(values.cpf);
        // delete values.cnpj
      }

      // delete values.tipoUsuario;
      setLoading(true);

      const logou = await handleLogin(values);
      if (logou) {
        resetForm(formik.initialValues);
        navigate("/");
        setErrorLogin(false);
      } else {
        setErrorLogin(true);
      }
      setLoading(false);
    },
  });

  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  // This function is triggered on the keyup event
  const checkCapsLock = (event) => {
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{zIndex: 'modal', backgroundColor:'#f5f5f5'}}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={crfrs} alt='logo crfrs' width='300px'/>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 1 }}>
            {errorLogin && (
              <Alert severity="error">
                Usuário ou senha inválidos
              </Alert>
            )}
            {isCapsLockOn && (<Alert severity='error'>Tecla CapsLock ligada</Alert>)}
            <Toolbar sx={{ gap: 2, justifyContent: "center" }}>
              <Radio
                checked={formik.values.tipoUsuario === "empresa"}
                onChange={() => formik.setFieldValue("tipoUsuario", "empresa")}
                name="tipoUsuario"
              />
              <FormLabel label>Empresa</FormLabel>
              <Radio
                checked={formik.values.tipoUsuario === "profissional"}
                onChange={() =>
                  formik.setFieldValue("tipoUsuario", "profissional")
                }
                name="tipoUsuario"
              />
              <FormLabel>Profissional</FormLabel>
            </Toolbar>
            {formik.values.tipoUsuario === "empresa" ? (
             // Retirada máscara de CNPJ para funcionar autocomplete do navegador
             // <InputMask
              //   mask="99.999.999/9999-99"
              //   value={formik.values.cnpj}
              //   onChange={formik.handleChange}
              //   name="cnpj" 
                             
              // >
              //   {(inputProps) => (
              //     <TextField
              //       margin="normal"
              //       fullWidth
              //       id="cnpj"
              //       name="cnpj"
              //       label="CNPJ"
              //       error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
              //       helperText={formik.touched.cnpj && formik.errors.cnpj}
              //       autoFocus={formik.values.tipoUsuario === "empresa"}
              //       sx={{backgroundColor:'white'}}
              //       InputLabelProps={{shrink: true}}
                   
              //     />
              //   )}
              // </InputMask>
              <TextField
              margin="normal"
              fullWidth
              id="cnpj"
              name="cnpj"
              label="CNPJ"
              error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
              helperText={formik.touched.cnpj && formik.errors.cnpj}
              autoFocus={formik.values.tipoUsuario === "empresa"}
              sx={{backgroundColor:'white'}}
              InputLabelProps={{shrink: true}}
              value={formik.values.cnpj}
              onChange={formik.handleChange}
              
            />
            ) : (
              <InputMask
                mask="999.999.999-99"
                value={formik.values.cpf}
                onChange={formik.handleChange}
                name="cpf"
              >
                {(inputProps) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    id="cpf"
                    name="cpf"
                    label="CPF"
                    {...inputProps}
                    error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                    helperText={formik.touched.cpf && formik.errors.cpf}
                    sx={{backgroundColor:'white'}}
                    InputLabelProps={{shrink: true}}
                    
                  />
                )}
              </InputMask>
            )}

            <TextField
              margin="normal"
              fullWidth
              id="senha"
              name="senha"
              label="Senha"
              type="password"
              value={formik.values.senha}
              onChange={formik.handleChange}
              error={formik.touched.senha && Boolean(formik.errors.senha)}
              helperText={formik.touched.senha && formik.errors.senha}
              sx={{backgroundColor:'white'}}
              onKeyUp={checkCapsLock}
              // autoComplete='senha'
            />
            <LoadingButton
              type="submit"
              loading={loading}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </LoadingButton>
          </Box>
        </form>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  usuario: state.AutenticacaoReducer.usuario,
});
export default connect(mapStateToProps)(Login);
