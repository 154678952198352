import { Box } from "@mui/material";

import TabelaBoletos from "../../tabelaBoletos";
import { Grid } from "@mui/material";
import Logs from "../../logs";

import TabelaHistoricoEmpresa from "../../TabelaHistoricoEmpresa";
import TituloDaPagina from "../../tituloDaPagina";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiEmpresa } from "../../../services/apiEmpresa";
import { connect } from "react-redux";
import EstruturaDaPagina from "../../estruturaDaPagina";
import RotuloMaisTexto from "../../rotuloMaisTexto/idex";
import TabelaHistoricoProfissional from "../../tabelaHistoricoProfissional";
import DeletarSolic from "../../deletarSolic";

const Solicitacao = ({ auth }) => {
  const status = "pendente";
  const { id } = useParams();
  const [idValido, setIdValido] = useState(true);
  const [logs, setLogs] = useState([]);
  const [dadosEmpresa, setDadosEmpresa] = useState([]);
  const [dadosProfissionais, setDadosProfissionais] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await apiEmpresa.get("/histStatus", {
          params: {
            idEmp: auth.idusuario,
            solicitacaoId: id,
          },
        });
        setLogs(data);
        const res = await apiEmpresa.get("/resumoSolic", {
          params: {
            solicitacaoId: id,
            idEmp: auth.idusuario,
          },
        });
        setDadosEmpresa(res.data.empresa || []);
        setDadosProfissionais(res.data.profissional || []);
      } catch (error) {
        setLogs([]);
      }
    })();
  }, []);

  return (
    <>
      <TituloDaPagina titulo={"Alteração de CR"} />
      <Box
        sx={{
          display: "flex",
          p: 1,
          gap: 2,
          backgroundColor: "background",
          justifyContent: "center",
          alignItems: "center",
          width: "max-content",
          mx: "auto",
        }}
      >
        <RotuloMaisTexto rotulo="Protocolo" texto={id} />
        <RotuloMaisTexto
          rotulo="Status"
          texto={logs ? logs[logs?.length - 1]?.status : ""}
        />
      </Box>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} md={6}>
          <Logs logs={logs} />
        </Grid>
        <Grid item xs={12} md={6}>
          {dadosEmpresa.length > 0 && (
            <TabelaHistoricoEmpresa dados={dadosEmpresa} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {dadosProfissionais.length > 0 && (
            <TabelaHistoricoProfissional dados={dadosProfissionais} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
});
export default connect(mapStateToProps)(Solicitacao);
