import { Container, Typography } from "@material-ui/core";
import { Box } from "@mui/material";

const InfoAtendimento = () => {
	let nroate = "657150";
  let nom = "Teste Conselho Regional de Farmacia - SC 1";
  let obs = "04/10/2022 09:48 Leandro Carvalho";
  let dat = "04/10/2022";
  let hor = "09:48";
  let detalhes_cpfcnpj_inscricao = "Docum.: 93.026.771/0001-39 // Nro Insc.: 99997 ";
	let nro = '656983';
	let ass = 'EXPEDIÇÃO CR';
	let srv = 'Certidoes Pessoa Juridica';
	let qtdCopias = '';
	let val = '20,23';
	let procpf = '';
	let pronom = '';
	
	return(
        <Box>
        <Box>
          <h2 >
            Atendimento: {nroate} // {nom}
          </h2>
          Obs: {obs}
          <h4>
            {dat} - {hor} {detalhes_cpfcnpj_inscricao}
          </h4>
        </Box>
        <Box sx={{
          width:'max-content',
          minWidth:300,
          border: 'solid thin #ddd',
        }}>
          <Box
            sx={{
              backgroundColor: "#f5f5f5",
              color: "#333",
              borderColor: "#ddd",
              py: 1,
              px: 2
            }}
          >
            <h3>Nº Prot.: {nro}</h3>
            {ass}
          </Box>
          <Box
            sx={{
              p: 2,
              display:'flex',
              flexDirection:'column',
              gap:1,
              textAlign:'center'
            }}
          >
						<Box>
							<div style={{color:'#31708f'}}>Serviço(s) Vinculado</div>
							<div>- {srv}{qtdCopias} = R$ {val}</div>
						</Box>
						<Box>
						<div style={{color:'#31708f'}}>Profissional Vinculado</div>
						<div> - {procpf} - {pronom} </div>
						</Box>
					</Box>
        </Box>
      </Box>
    )
}

export default InfoAtendimento;