import { Toolbar, Typography, Divider, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Paper } from "@mui/material";
import { rotulosEmpresa } from "../../../rotulos/empresa";
import { rotulosProfissional } from "../../../rotulos/profissional";
import { semanaEmpresa } from "../../../rotulos/semanaEmpresa";
import { semanaProfissional } from "../../../rotulos/semanaProfissional";
import RotuloMaisTexto from "../../rotuloMaisTexto/idex";
import { horasExibicao } from "../../../helpers";
import moment from "moment";
import { connect } from "react-redux";
import Loading from '../../loading';


const CRTexto = ({ dados, prof, capitalSocial, loading }) => {
  return (
    <>
    {loading  && (<Loading loading={true}/>)}
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={12} md={ prof.length ? 6 : 12}>
        <Box component={Paper} sx={{ p: { xs: 1, md: 2 } }}>
          <Grid container rowSpacing={1} columnSpacing={1} >
            <Grid item xs={12}>
              <Typography variant="h4" color="primary">
                Dados da empresa
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography color="primary" variant="h6">
                  Registro
                </Typography>
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.cnpj}
                  texto={dados.cnpj}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.razao}
                  texto={dados.razao}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.nome}
                  texto={dados.nome}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.capitalsocial_idcapitalsocial}
                  texto={
                    capitalSocial.find(
                      (c) =>
                        c.idcapitalsocial ===
                        dados.capitalsocial_idcapitalsocial
                    )?.texto || ""
                  }
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.tipo}
                  texto={dados.tipo}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.subtipo}
                  texto={dados.subtipo}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography color="primary" variant="h6">
                  Localização
                </Typography>
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.cep}
                  texto={dados.cep}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.logradouro}
                  texto={dados.logradouro}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.numero}
                  texto={dados.numero}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.complenum}
                  texto={dados.complenum}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.bairro}
                  texto={dados.bairro}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.cidade}
                  texto={dados.cidade}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.uf_endereco}
                  texto={dados.uf_endereco}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography color="primary" variant="h6">
                  Contatos
                </Typography>
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.telefone}
                  texto={dados.telefone}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.celular}
                  texto={dados.celular}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.email1}
                  texto={dados.email1}
                />
                <RotuloMaisTexto
                  rotulo={rotulosEmpresa.email2}
                  texto={dados.email2}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography color="primary" variant="h6">
                  Horário de funcionamento
                </Typography>
                {semanaEmpresa.map((dia, index) => (
                  <RotuloMaisTexto
                    rotulo={rotulosEmpresa[dia]}
                    texto={horasExibicao(dados[dia])}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {prof && prof.length > 0 && (
      <Grid item sm={12} md={6}>
        <Box component={Paper} sx={{ p: { xs: 1, md: 2 } }}>
          <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12}>
              <Typography variant='h4' color='primary'>
                Lista de profissionais
              </Typography>
              <Divider/>
            </Grid>
            {prof?.map((p, index) => (
                <>
                  <Grid item xs={12} key={`prof-${index}`}>
                    <Box sx={{display:'flex', gap:2, alignItems:'center'}}>
                    <Typography color="primary" variant="h6">
                      {p.nome}
                    </Typography>
                    {p.novo && (
                      <Box sx={{px:1, backgroundColor:'secondary.main', borderRadius:'10px'}}>
                        <Typography variant='subtitle' color='primary.main'>Novo</Typography>
                      </Box>
                    )}
                    </Box>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RotuloMaisTexto
                      rotulo={rotulosProfissional.cpf}
                      texto={p.cpf}
                    />
                    <RotuloMaisTexto
                      rotulo={rotulosProfissional.pro_tipo}
                      texto={p.pro_tipo}
                    />
                    <RotuloMaisTexto
                      rotulo={rotulosProfissional.pro_vinculo}
                      texto={p.pro_vinculo}
                    />
                    <RotuloMaisTexto
                      rotulo={rotulosProfissional.dataini}
                      texto={
                        moment(p.dataini).isValid()
                          ? moment(p.dataini).format("DD/MM/YYYY")
                          : ""
                      }
                    />
                    <RotuloMaisTexto
                      rotulo={rotulosProfissional.datafim}
                      texto={
                        moment(p.datafim).isValid()
                          ? moment(p.datafim).format("DD/MM/YYYY")
                          : ""
                      }
                    />
                    <RotuloMaisTexto
                      rotulo={rotulosProfissional.escala}
                      texto={p.escala === true ? "Sim" : "Não"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {semanaProfissional.map((dia, idx) => (
                      <RotuloMaisTexto
                        rotulo={rotulosProfissional[dia]}
                        texto={horasExibicao(p[dia])}
                      />
                    ))}
                  </Grid>
                </>
              ))}
          </Grid>
        </Box>
      </Grid>
      )}
    </Grid>
    </>
  );
};



const mapStateToProps = (state) => ({
  loading: state.CRTReducer.loading
});
export default connect(mapStateToProps)(CRTexto);