import * as yup from "yup";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];
const MAX_SIZE = 20000000;

const validar = (dados) => {
  if (dados.empresa !== null && dados.profissionais.length) {
		return validacaoTotal;
  }

  if (dados.empresa !== null) {
    return validacaoEmpresa;
  }
  if (dados.profissionais !== null) {
    return validacaoProfissional;
  }
};

const validacaoTotal = yup.object({
    empresa: yup.array().of(
      yup.object().shape({
        anexo: yup
          .mixed()
          .test("anexo", "Tamanho máximo permitido 20 MB", (value) => {
            if (value === null || typeof value === "string") return true;
            return value.size <= MAX_SIZE;
          })
          .test(
            "anexo",
            "Formatos suportados .jpg, .jpeg, .png e .pdf",
            (value) => {
              if (value === null || typeof value === "string") return true;
              return SUPPORTED_FORMATS.includes(value.type);
            }
          )
          .required("Anexo obrigatório"),
      })
    ),
    profissionais: yup.array().of(
      yup.object().shape({
        anexoSelecionado: yup.object().shape({
          id: yup
          .mixed("Selecione um documento").when("anexo", {
            is: (value) => value !== null,
            then: yup.mixed().test("id", "Selecione um documento", (val) => {
              return val !== null;
            }),
          })
          .required("É obrigatório selecionar o documento de vínculo do profissional"),
          anexo: yup
            .mixed()
            .test("anexo", "Tamanho máximo permitido 20 MB", (value) => {
              if (
                value === null ||
                typeof value === "string" ||
                value === undefined
              )
                return true;
              return value.size <= MAX_SIZE;
            })
            .test(
              "anexo",
              "Formatos suportados .jpg, .jpeg, .png e .pdf",
              (value) => {
                if (
                  value === null ||
                  typeof value === "string" ||
                  value === undefined
                )
                  return true;
                return SUPPORTED_FORMATS.includes(value.type);
              }
            )
            .required("Anexo obrigatório"),
        }),
      })
    ),
  });

const validacaoEmpresa = yup.object().shape({
  empresa: yup.array().of(
    yup.object().shape({
      anexo: yup
        .mixed()
        .test("anexo", "Tamanho máximo permitido 20 MB", (value) => {
          if (value === null || typeof value === "string") return true;
          return value.size <= MAX_SIZE;
        })
        .test(
          "anexo",
          "Formatos suportados .jpg, .jpeg, .png e .pdf",
          (value) => {
            if (value === null || typeof value === "string") return true;
            return SUPPORTED_FORMATS.includes(value.type);
          }
        )
        .required("Anexo obrigatório"),
    })
  ),
});

const validacaoProfissional = yup.object().shape({
  profissionais: yup.array().of(
    yup.object().shape({
      anexoSelecionado: yup.object().shape({
        id: yup
        .mixed("Selecione um documento").when("anexo", {
          is: (value) => value !== null,
          then: yup.mixed().test("id", "Selecione um documento", (val) => {
            return val !== null;
          }),
        })
        .required("É obrigatório selecionar o documento de vínculo do profissional"),
        anexo: yup
        .mixed()
        .test("anexo", "Tamanho máximo permitido 20 MB", (value) => {
          if (
            value === null ||
            typeof value === "string" ||
            value === undefined
          )
            return true;
          return value.size <= MAX_SIZE;
        })
        .test(
          "anexo",
          "Formatos suportados .jpg, .jpeg, .png e .pdf",
          (value) => {
            if (
              value === null ||
              typeof value === "string" ||
              value === undefined
            )
              return true;
            return SUPPORTED_FORMATS.includes(value.type);
          }
        )
        .required("Anexo Obrigatório")
      })
    })
  ),
})

export default validar;
