import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    grupo: {
      marginBottom: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    titulo: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    textField: {
      border: " solid 2px red",
    },
    cabecalho: {
        backgroundColor: '#b7b7b8',
        fontSize: '1.2rem',
    }
  }));
  
  export default useStyles;