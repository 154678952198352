import { DialogContent, DialogTitle, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react";

const ModalImgLink = ({link,tipo, titulo, handleClose}) => {
	return(
			<>
			<DialogTitle>
			<Toolbar sx={{display:'flex', justifyContent:'space-between'}}>
				{titulo}
				<CloseIcon onClick={() => handleClose()} sx={{float:'right', '&:hover':{cursor:'pointer'}}}/>
			</Toolbar>
		</DialogTitle>
		<DialogContent>
			{tipo ==='pdf' ? (
				 <embed src={link} width="100%" height={'700px'}/>
			): (
				<img src={link} alt={titulo} width='100%'/>
			)}
		</DialogContent>
		</>
    );
}

export default ModalImgLink;