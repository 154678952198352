import imgCabecalho from "../../../assets/images/cabecalho-rs.png";
import Box from "@mui/material/Box";
const HeaderAtendimento = () => {
  return (
    <>
      <img src={imgCabecalho} width="100%" alt="Cabeçalho com logo do CRFRS" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            p: 2,
            textAlign: "center",
          }}
        >
          <strong>
            INFORMAÇÕES SOBRE O PROTOCOLO TAMBÉM PODEM SER OBTIDAS
            <br />
            NO PORTAL - <u>http://www.crfrs.org.br</u> &raquo; CONSULTAS -
            ACESSO RÁPIDO
          </strong>
        </Box>
        <Box
          sx={{
            border: "solid thin black",
            p: 2,
            textAlign: "center",
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Os protocolos recebidos junto
          ao CRF-RS, <br />
          <b>
            que não tiverem suas pendências regularizadas dentro do prazo
          </b>, <br />
          serão arquivados e os boletos relativos aos mesmos não serão
          reaproveitados.
        </Box>
        <table width="100%" style={{border: 'solid black thin', borderCollapse:'collapse'}}>
          <tr >
					<td align="center" width="50%" style={{border: 'solid black thin'}}>
            <strong>
						SECCIONAL PASSO FUNDO
            <br />
            Fone: (54) 3045.1100
            <br />
            seccionalpf@crfrs.org.br
						</strong>
					</td>
          <td align="center" width="50%" style={{border: 'solid black thin'}}>
            <strong>
						SECCIONAL SANTA MARIA
            <br />
            Fone: (55) 3025.2423
            <br />
            seccionalsm@crfrs.org.br
						</strong>
          </td>
					</tr>
        </table>
      </Box>
    </>
  );
};

export default HeaderAtendimento;
