import { combineReducers } from "redux";

import AutenticacaoReducer from "../reducers/AutenticacaoReducer";
import CRTReducer from "../reducers/CRTReducer";

export const handleLogout = (dispatch)=>{
    
  dispatch({
    type:'LOGOUT',
  })
  return true
}

const appReducer = combineReducers({
  AutenticacaoReducer,
  CRTReducer
  
})

export const rootReducer = (state,action) => {
  if(action.type === 'LOGOUT'){
    state = undefined;
  }
  return appReducer(state,action);
}