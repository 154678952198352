import { getSolicitacoes, setLoading, getRtHistoricoAceitas } from "../../store/actions/CRTActions";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import EstruturaDaPagina from "../../components/estruturaDaPagina";
import TituloDaPagina from "../../components/tituloDaPagina";
import CustomSkeleton from '../../components/customSkeleton';

import Box from "@material-ui/core/Box";
import { Alert, IconButton, TableCell, TableHead, TableRow, Table, Link, Typography, Dialog } from "@mui/material";
import moment from "moment";
import SearchIcon from '@mui/icons-material/Search';
import { formatarEndereco } from "../../helpers";
import { useNavigate } from "react-router-dom";

const ProfHisttaSolic = ({auth, loading}) => {
  const navigate = useNavigate();
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [solicitacoesHistorico, setSolicitacoesHistorico] = useState([]);

  useEffect(()=>{
    (async () => {
		setLoading(true)
		//buscar solicitacoes para aprovacao
		try{
			const data = await getSolicitacoes(auth.idusuario, auth.tipoUsuario)
			if(data.length) {
				let ordem = data.sort((a,b) => moment(b.dataAbertura).diff(a.dataAbertura))
				setSolicitacoes(ordem)
			}else{
				setSolicitacoes(false)
			}	
		}catch{
			setSolicitacoes(false)
		}
	
		//buscar historico de solicitacoes
		try{
			const data = await getRtHistoricoAceitas(auth.idusuario)
			data.length ? setSolicitacoesHistorico(data) : setSolicitacoesHistorico(false)
		}catch{
			setSolicitacoesHistorico(false)
		}
		setLoading(false)
	})();
  },[]) 

  return (
		<>
			<TituloDaPagina titulo='Lista de Alterações de CR'/>
			<Box>
				{loading && (<CustomSkeleton/>)}
				{(!loading && !solicitacoes && !solicitacoesHistorico) ? (<Alert severity='warning'>Nenhuma alteração de CR encontrada</Alert>) : null}
				{!loading && solicitacoes.length ? (
					<Box>
						<Typography variant="h5" sx={{ my: 2 }}>
							Aguardando Aprovação
				  		</Typography>
						<Table size='small'>
							<TableHead>
								<TableRow>
									<TableCell>Data de abertura</TableCell>
									<TableCell>Protocolo</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Empresa</TableCell>
									<TableCell>Endereço</TableCell>
									<TableCell>Cidade</TableCell>
									<TableCell>Visto</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							{solicitacoes.length && solicitacoes.map((solic,index) => (
								<TableRow>
									<TableCell>{moment(solic.dataAbertura,'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY - HH:mm')}</TableCell>
									<TableCell>
										<Link 
											onClick={()=> navigate(`/solic/${solic.idempresa}/${solic.solicitacao_id}`)}
											sx={{'&:hover':{cursor:'pointer'}}}
										>
											{solic.solicitacao_id}
										</Link>
									</TableCell>
									<TableCell>{solic.status}</TableCell>
									<TableCell>{solic.razao}</TableCell>
									<TableCell>
										{formatarEndereco(solic.logradouro, solic.numero, solic.complenum, '')}
									</TableCell>
									<TableCell>
										{solic.cidade}
									</TableCell>
									<TableCell>
										{solic.visto_em !== null && (
											moment(solic.visto_em,'YYYY-MM-DD').format('DD/MM/YYYY')
										)}
									</TableCell>
									<TableCell>
										<IconButton 
											onClick={()=> navigate(`/solic/${solic.idempresa}/${solic.solicitacao_id}`)}
										>
											<SearchIcon/>
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</Table>
					</Box>
				) : null}
				{!loading && solicitacoesHistorico.length ? (
					<Box>
						<Typography variant="h5" sx={{ my: 2 }}>
							Histórico de Alterações
				  		</Typography>
						<Table size='small'>
							<TableHead>
								<TableRow>
									<TableCell>Data de abertura</TableCell>
									<TableCell>Protocolo</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Empresa</TableCell>
									<TableCell>Endereço</TableCell>
									<TableCell>Cidade</TableCell>
									<TableCell>Visto</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							{solicitacoesHistorico.length && solicitacoesHistorico.map((solic,index) => (
								<TableRow>
									<TableCell>{moment(solic.dataAbertura,'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY - HH:mm')}</TableCell>
									<TableCell>
										<Link 
											onClick={()=> navigate(`/solic/${solic.idempresa}/${solic.solicitacao_id}`)}
											sx={{'&:hover':{cursor:'pointer'}}}
										>
											{solic.solicitacao_id}
										</Link>
									</TableCell>
									<TableCell>{solic.status}</TableCell>
									<TableCell>{solic.razao}</TableCell>
									<TableCell>
										{formatarEndereco(solic.logradouro, solic.numero, solic.complenum, '')}
									</TableCell>
									<TableCell>
										{solic.cidade}
									</TableCell>
									<TableCell>
										{solic.visto_em !== null && (
											moment(solic.visto_em,'YYYY-MM-DD').format('DD/MM/YYYY')
										)}
									</TableCell>
									<TableCell>
										<IconButton 
											onClick={()=> navigate(`/solic/${solic.idempresa}/${solic.solicitacao_id}`)}
										>
											<SearchIcon/>
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</Table>
					</Box>
					
				) : null}
			</Box>
		</>
	)
}

const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
  loading: state.CRTReducer.loading
});
export default connect(mapStateToProps)(ProfHisttaSolic);
