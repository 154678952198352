import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Step,
  Stepper,
  StepLabel,
  StepContent,
} from "@mui/material";

import { Paper } from "@mui/material";

import FileOpenIcon from "@mui/icons-material/FileOpen";
import moment from "moment";

const Logs = ({ logs }) => {
  return (
    <Box
      component={Paper}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h5">Histórico de ações</Typography>
      <Stepper orientation="vertical">
        {logs?.map((log, index) => (
          <Step active={true}>
            <StepLabel StepIconProps={{ icon: "" }}>
                <Typography color="primary.main">{log.hist_status}</Typography>
            </StepLabel>
            <StepContent>
              <Typography variant="body">{moment(log.dia,'YYYY-MM-DD').format('DD/MM/YYYY')}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default Logs;

<Table>
  <TableHead>
    <TableRow>
      <TableCell>Pedido</TableCell>
      <TableCell>Especificação</TableCell>
      <TableCell>Antes</TableCell>
      <TableCell>Depois</TableCell>
      <TableCell>Anexo</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Observação</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    <TableRow>
      <TableCell>Alteração de dados da empresa</TableCell>
      <TableCell>Capital Social</TableCell>
      <TableCell>R$ 0,00 à R$ 50.000,00</TableCell>
      <TableCell>R$ 50.001,00 à R$ 200.000,00</TableCell>
      <TableCell>
        <FileOpenIcon />
      </TableCell>
      <TableCell>Aprovado</TableCell>
      <TableCell></TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Alteração de dados da empresa</TableCell>
      <TableCell>Telefone</TableCell>
      <TableCell>33333333</TableCell>
      <TableCell>0800 642 8222</TableCell>
      <TableCell>
        <FileOpenIcon />
      </TableCell>
      <TableCell>Aprovado</TableCell>
      <TableCell></TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Edição de profissional</TableCell>
      <TableCell>Marcelo Kubiaki dos Santos Teste</TableCell>
      <TableCell colSpan={2} align="center">
        Ver mais
      </TableCell>
      <TableCell>
        <FileOpenIcon />
      </TableCell>
      <TableCell>Rejeitado</TableCell>
      <TableCell>
        Conflito de horários com outro estabelecimento em que o profissional
        atua.
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell>Inclusão de profissional</TableCell>
      <TableCell>Cauane da Silva Melo</TableCell>
      <TableCell colSpan={2} align="center">
        Ver mais
      </TableCell>
      <TableCell>
        <FileOpenIcon />
      </TableCell>
      <TableCell>Pendente</TableCell>
      <TableCell></TableCell>
    </TableRow>
    <TableRow>
      <Table>
        <TableHead>
          <TableCell>Campo</TableCell>
          <TableCell>Valor</TableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Hierarquia</TableCell>
            <TableCell>Assistente</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Vínculo</TableCell>
            <TableCell>Contratado</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Data de início</TableCell>
            <TableCell>20/06/2022</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Segunda-feira</TableCell>
            <TableCell>09:00 às 14:00</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Terça-feira</TableCell>
            <TableCell>09:00 às 14:00</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Quarta-feira</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Quinta-feira</TableCell>
            <TableCell>09:00 às 14:00</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Sexta-feira</TableCell>
            <TableCell>09:00 às 12:00; 13:00 às 17:00</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Sábado</TableCell>
            <TableCell>09:00 às 12:00; 13:00 às 17:00</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Domingo</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableRow>
  </TableBody>
</Table>;
