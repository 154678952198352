import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress, Collapse } from "@mui/material";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import simbolo from "../../assets/images/eCRF-vertical.svg";
import ErroApi from "../erroApi";
import { Divider } from "@mui/material";
import Mock from '../mock';
import {Link} from "@mui/material";
const Menu = ({ menus, usuario, nomeUsuario, listaFiliais, endereco }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [openSecondLevel, setOpenSecondLevel] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!menus) setLoading(false);
    if (menus.length) setLoading(false);
  }, [menus]);

  const handleClick = (e) => {
    setOpen({ [e]: !open[e] });
  };

  const handleClickSecondLevel = (e) => {
    setOpenSecondLevel({ [e]: !openSecondLevel[e] });
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <List disablePadding>
        <ListItem key="user">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              width: 1,
              p: 2,
              
            }}
          >
            <img src={simbolo} width="150px" alt="crfrs" />
            <Box>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ textTransform: "capitalize" }}
            >
              {nomeUsuario !== "" ? nomeUsuario : usuario}
            </Typography>
            <small>{endereco}</small>
            </Box>
          </Box>
        </ListItem>
        <Divider />
        <ListItem
          key="inicio"
          disablePadding
          sx={{
            mt: 1,
            px: 1,
            backgroundColor: "background.light",
            color: "secondary.contrastText",
            "&:hover": {
              color: "secondary.contrastText",
              backgroundColor: "background.main",
            },
          }}
        >
          <ListItemButton
            onClick={() => navigate("/")}
            sx={{
              "&:hover": {
                background: "none",
              },
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "middle" }}>
              <HomeIcon color="primary" />
              <ListItemText
                primary="Início"
                sx={{
                  fontWeight: "400",
                  color: "primary.main",
                }}
              />
            </Box>
          </ListItemButton>
        </ListItem>
        <Divider />
        {usuario === "empresa" && listaFiliais?.length > 0 && (
          <>
            <ListItem
              key='selecionar-empresa'
              onClick={() => navigate("/selecionar-empresa")}
              disablePadding
            >
              <ListItemButton>
                <ListItemText>Selecionar empresa</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        )}
        {!menus && <ErroApi msg="Menu indisponível" />}
        {loading && (
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <CircularProgress size={20} />
          </Box>
        )}
        {menus &&
          menus.map((menu, index) => (
            <>
              <ListItem
                key={menu.title}
                onClick={() => handleClick(menu.title)}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary={menu.title} />
                  <ListItemIcon>
                    {open[menu.title] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <Divider />
              {menu.data[0].data === undefined
                ? menu.data.map((submenu, index) => (
                    <Collapse
                      in={open[menu.title]}
                      timeout="auto"
                      unmononexit="true"
                    >
                      <List  disablePadding>
                        <ListItem
                          key={submenu.name}
                          component={Link}
                          href={submenu.path}
                        >
                          <ListItemButton>
                            <ListItemText primary={submenu.name} />
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                      </List>
                    </Collapse>
                  ))
                : menu.data.map((submenu, index) => (
                    <Collapse
                      in={open[menu.title]}
                      timeout="auto"
                      unmountonexit="true"
                    >
                      <List  disablePadding>
                        <ListItem
                          key={submenu.name}
                          onClick={() => handleClickSecondLevel(submenu.name)}
                        >
                          <ListItemButton>
                            <ListItemText primary={submenu.name} />
                            <ListItemIcon>
                              {openSecondLevel[submenu.name] ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                        {submenu.data.map((terceiroMenu, index) => (
                          <Collapse
                            in={openSecondLevel[submenu.name]}
                            timeout="auto"
                            unmountonexit="true"
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                key={terceiroMenu.name}
                                component={Link}
                                href={terceiroMenu.path}
                              >
                                <ListItemButton>
                                  <ListItemText primary={terceiroMenu.name} />
                                </ListItemButton>
                              </ListItem>
                              <Divider />
                            </List>
                          </Collapse>
                        ))}
                      </List>
                    </Collapse>
                  ))}
            </>
          ))}
      </List>
    </Box>
  );
};

// export default Teste;
const mapStateToProps = (state) => ({
  // auth: state.AutenticacaoReducer,
  nomeUsuario: state.AutenticacaoReducer.nome,
  endereco: state.AutenticacaoReducer.endereco,
  listaFiliais: state.AutenticacaoReducer.listaFiliais,
  state,
});
export default connect(mapStateToProps)(Menu);
