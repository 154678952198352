import { Box} from "@mui/system";
import {Link} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Paper, Typography } from "@mui/material";
import Mock from "../../mock";

const PagamentoRealizado = ({protocolo, navegar}) => {
    return(
			<Box component={Paper} sx={{display:'flex', flexDirection:'column', gap: 3, p:3, alignItems:'center'}}>
				<Typography variant='h4'>Pagamento realizado com sucesso! <Mock/></Typography>
				<CheckCircleOutlineIcon color='success' sx={{fontSize:'5rem'}}/>
				<Box>
					<Typography variant='h6'>Acesse sua solicitação no link abaixo:</Typography>
					<Box sx={{p:4, backgroundColor:'lightgray', display:'flex',justifyContent:'center'}}>
						<Link 
							onClick={() => navegar()}
							sx={{'&:hover':{cursor:'pointer'}}}
						>
							{protocolo}
						</Link>
					</Box>
			</Box>
			</Box>
    )
}
export default PagamentoRealizado;