import axios from 'axios';
const token = 'xc458e74f2'

export const loginInterno = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api4/login`,
  headers:{ 
    'token': token,
    'content-type': 'application/x-www-form-urlencoded'
  },
  
})

export const loginInternoPorEmail = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api4/usuario`,
  headers:{ 
    'content-type': 'application/x-www-form-urlencoded'
  }
})