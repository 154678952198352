import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const RotuloMaisTexto = ({rotulo,texto}) => {
  return (
    <Box sx={{ display: "flex", gap: .5, flexWrap:'wrap' }}>
      <Typography fontWeight="bold">{rotulo}:</Typography>
      <Typography>{texto}</Typography>
    </Box>
  );
};

export default RotuloMaisTexto;
