import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, IconButton } from "@mui/material";
const Mock = () => {
  return (
    <Tooltip title="Dados fake" placement="top-start">
      <IconButton>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default Mock;