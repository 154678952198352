import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./pages/login";
import Welcome from "./components/welcome";

import Alterar from "./pages/crt/alterarCRT";
import HistoricoCRT from "./pages/crt/historicoCRT";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import Anexos from "./pages/crt/alterarCRT/anexos";
import Solicitacao from "./components/crt/solicitacao";
import Pagamento from "./pages/crt/alterarCRT/pagamento";
import ProfSolic from "./pages/profissional/profSolic";
import ProfHisttaSolic from "./pages/profissional/profHistSolic";
import FormularioCRT from "./pages/crt/alterarCRT/formularioCRT";
import SelecionarEmpresa from "./pages/selecionarEmpresa";
import MenuManual from "./pages/layout/menuManual";
import Atendimento from "./pages/crt/atendimento";
import Redirecionar from './components/redirecionar';
import NotFound from './pages/notFound';
import Pendencias from "./pages/crt/alterarCRT/pendencias";

function Routers({ autenticacao }) {
  var auth = autenticacao.autenticacao.estaLogado;
  var { tipoUsuario } = autenticacao.autenticacao;
  return (
    <BrowserRouter>
      <Routes>
        {!auth ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        ) : (
          
          <Route path='/' element={<MenuManual/>}>
            <Route path="/" element={<Welcome />} />
            {tipoUsuario === "empresa" ? (
              <>
                {autenticacao.listaFiliais && autenticacao.listaFiliais.length > 0 && (
                  <Route path='/selecionar-empresa' element={<SelecionarEmpresa/>}/>
                )}
                <Route path="/empSolic" element={<Alterar />}>
                  <Route path="/empSolic/dados" element={<FormularioCRT/>} />
                  <Route path="/empSolic/anexos" element={<Anexos />} />
                  <Route path="/empSolic/pagamento/:id" element={<Pagamento />} />
                </Route>
                  <Route path="/pendencias" element={<Pendencias/>}/>
                <Route path="/histSolic" element={<HistoricoCRT />} />
                <Route path="/histSolic/:id" element={<Solicitacao />} />
                <Route
                path="*"
                element={
                <Redirecionar time="10" url="/">
                  <NotFound />
                </Redirecionar>
              }
            />
              </>
            ) : (
              <>
                <Route path="/histSolic" element={<ProfHisttaSolic />} />
                <Route path='/solic/:idEmp/:solic' element={<ProfSolic />}/>
                <Route
                path="*"
                element={
                <Redirecionar time="10" url="/">
                  <NotFound />
                </Redirecionar>
              }
            />
              </>
            )}
          </Route>
        )}
        <Route path='atendimento' element={<Atendimento/>}/>
        <Route path="*" element={<Navigate to="/" replace />} />
        
      </Routes>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  autenticacao: state.AutenticacaoReducer,
});
export default connect(mapStateToProps)(Routers);
