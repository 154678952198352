import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import {Table} from "@mui/material";
import {rotulosProfissional} from '../../rotulos/profissional';
import moment from "moment";

const TabelaHistoricoProfissionalInterna = ({ dados, ehNovo }) => {
  const converterParaTexto = (key,value) => {
		switch(key){
			case 'dataini':
				return moment(value).format('DD/MM/YYYY')
			case 'datafim':
				if(value === '0000-00-00') return ''
				return moment(value).format('DD/MM/YYYY')
			case 'escala' :
				return value === "0" ? 'Não' : 'Sim'
			default:
				return value
		}
	}

	return (
	<>
		{ehNovo ? (
		 <Table>
		 <TableHead>
			 <TableRow>
				 <TableCell>Pedido</TableCell>
				 <TableCell>Dado</TableCell>
			 </TableRow>
		 </TableHead>
		 <TableBody>
			 {dados.map((d) => (
				 <TableRow>
					 <TableCell>{rotulosProfissional[d.altera]}</TableCell>
					 <TableCell>{converterParaTexto(d.altera,d.para)}</TableCell>
				 </TableRow>
			 ))}
		 </TableBody>
	 </Table>
		)
		: 
		(
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Pedido</TableCell>
					<TableCell>Antes</TableCell>
					<TableCell>Depois</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{dados.map((d) => (
					<TableRow>
						<TableCell>{rotulosProfissional[d.altera]}</TableCell>
						<TableCell>{converterParaTexto(d.altera,d.de)}</TableCell>
						<TableCell>{converterParaTexto(d.altera,d.para)}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		)}
		</>
	)
};

export default TabelaHistoricoProfissionalInterna;