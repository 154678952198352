import { apiEmpresa } from "../../services/apiEmpresa";
import { apiProfissional } from "../../services/apiProfissional";
import { handleLogout } from "../../store/reducers";
import { formatarCapital, getToken } from "../../helpers";
import {store} from '../index';

const dispatch = store.dispatch;

export const getFormulario = async (id,tipoUsuario) => {
 
  try {
    const api = tipoUsuario === 'empresa' ? apiEmpresa : apiProfissional;
    const { data } = await api.get("/formulario", {
      params: {
        idEmp: id,
      },
    });
    dispatch({
      type: "GET_FORMULARIO",
      idempresa: data[0].idempresa,
      nome: data[0].nome,
      razao: data[0].razao,
      cnpj: data[0].cnpj,
      capitalsocial_idcapitalsocial: data[0].capitalsocial_idcapitalsocial,
      empresa_categoria: data[0].empresa_categoria,
      cep: data[0].cep,
      logradouro: data[0].logradouro,
      numero: data[0].numero,
      complenum: data[0].complenum,
      bairro: data[0].bairro,
      cidade: data[0].cidade,
      uf_endereco: data[0].uf_endereco,
      hr_seg: data[0].hr_seg,
      hr_ter: data[0].hr_ter,
      hr_qua: data[0].hr_qua,
      hr_qui: data[0].hr_qui,
      hr_sex: data[0].hr_sex,
      hr_sab: data[0].hr_sab,
      hr_dom: data[0].hr_dom,
      telefone: data[0].telefone,
      celular: data[0].celular,
      email1: data[0].email1,
      email2: data[0].email2,
      idtipo: data[0].idtipo,
      idsubtipo:data[0].idsubtipo,
      tipo:data[0].tipo,
      subtipo:data[0].subtipo,
      inscricao:data[0].inscricao,
      isento:data[0].isento
    });
    dispatch({
      type: "FORMULARIO_VALIDO",
      formularioValido: true,
    });
    
  } catch (error) {
    dispatch({
      type: "FORMULARIO_VALIDO",
      formularioValido: false,
    });
  }
  finally{
    return
  }
};

export const getSolicAberta = async (idEmp) => {
  try{
    const res = await apiEmpresa.get('/solicAberta', {
      params:{
        idEmp
      }
    })
        
    if(res.status === 204){
      dispatch({
        type:"SET_SOLICITACAO_ABERTA",
        payload: {
          status: "0",
          msg: '',
          solic: '',
          boleto:''
        }
      })
      return '0';
    }else{
      dispatch({
        type:"SET_SOLICITACAO_ABERTA",
        payload: {
          status: res.data.status_id,
          msg: res.data.msg,
          solic: res.data.solicitacaoId,
          boleto:res.data.boleto,
          mensagemPendencia: res.data.mensagem
        }
      })
      return res.data.status_id
    }
  }
  catch(error){
    dispatch({
      type:"SET_SOLICITACAO_ABERTA",
      payload: {
        status: "0",
        msg: '',
        solic: '',
        boleto:''
      }
    })
    return false
  }
  
}

export const postFormularioTemporario = async (values) => {
  dispatch({
    type: "SET_FORMULARIO_TEMPORARIO",
    formularioEditado: true,
    payload: values,
  });
};

export const resetTemporario = async () => {
  dispatch({
    type: "RESET_FORMULARIO_TEMPORARIO",
  });
 
};

export const getListaProfissionais = async (id,tipoUsuario) => {
  
  const api = tipoUsuario === 'empresa' ? apiEmpresa : apiProfissional;
  try {
    const { data } = await api.get("/pfListar", {
      params: {
        idEmp: id,
      },
    });
    
    let newData = {}
    if(data[1]?.novos !==undefined) {
      data[1].novos.map(p => p.novo = true);
      newData = data[0].existentes.concat(data[1].novos)
    }
    else{
      newData = data;
    }

    newData.map(d => d.escala === "1" ? d.escala = true : d.escala = false);
    
    dispatch({
      type: "GET_LISTA_PROFISSIONAIS",
      payload: newData,
    });
    return true
  } catch (error) {
    dispatch({
      type: "RESET_LISTA_PROFISSIONAIS",
    });
    console.log('error ', error)
    return false;
  }
  
};

export const postListaProfissionaisTemporario = async (values) => {

  dispatch({
    type: "SET_LISTA_PROFISSIONAIS_TEMPORARIO",
    formularioEditado: true,
    payload: values,
  });
};

export const resetProfissionaisTemporario = async () => {
  dispatch({
    type: "RESET_PROFISSIONAIS_TEMPORARIO",
  });
  dispatch({
    type:"SET_FUNCIONARIOS_PENDENCIA",
    payload:[]
  })
};

export const resetPendencias = () => {
  dispatch({
    type:"SET_FUNCIONARIOS_PENDENCIA",
    payload:[]
  })
  dispatch({
    type:"RESET_EMPRESA_PENDENCIA",
  })
}


export const verificarAlteracoes = async (
  empresaInicio,
  empresaFim,
  profissionaisInicio,
  profissionaisFim,
) => {
  let newForm = {
    idEmp: empresaInicio.idempresa,
    data: {},
  };

  // loop percorre todos dados da empresa e adiciona ao objeto newForm.empresa o que foi modificado
  for (let prop in empresaInicio) {
    if (empresaInicio[prop] !== empresaFim[prop]) {
      if (newForm.data.empresa === undefined) {
        newForm.data.empresa = {};
      }
      newForm.data.empresa = {
        ...newForm.data.empresa,
        [prop]: {
          de: empresaInicio[prop],
          para: empresaFim[prop],
        },
      };
    }
  }

  // loop percorre todos dados da profissionais e adiciona ao objeto newForm.idprofissional o que foi modificado
  profissionaisFim.map((pro, index) => {
    let alteracoes = {};
    if (profissionaisInicio[index] === undefined) {
      if (newForm.data.profissional === undefined) {
        newForm.data.profissional = {};
      }
      for (let property in pro) {
        if (pro[property] !== "") {
          alteracoes = {
            ...alteracoes,
            [property]: { de: "", para: pro[property] },
          };
        }
        newForm.data.profissional[pro.idprofissional] = alteracoes;
      }
    } else {
      for (let property in pro) {
        if (pro[property] !== profissionaisInicio[index][property]) {
          alteracoes = {
            ...alteracoes,
            [property]: {
              de: profissionaisInicio[index][property],
              para: pro[property],
            },
          };
        }

        if (JSON.stringify(alteracoes) !== "{}") {
          if (newForm.data.profissional === undefined) {
            newForm.data.profissional = {};
          }
          newForm.data.profissional[pro.idprofissional] = alteracoes;
        }
      }
    }
  });

  const esc = encodeURIComponent;
  const query = Object.keys(newForm)
    .map((k) => `${esc(k)}=${esc(newForm[k])}`)
    .join("&");
  try {
    const { data } = await apiEmpresa.post("/valida", newForm);

    // monta objeto para ser utilizado na tela de anexos

    if(data.profissional !== undefined) {
      dispatch({
        type:'SET_FUNCIONARIOS_PENDENCIA',
        payload:data.profissional
      })
    }

    if(data.empresa !== undefined) {
      dispatch({
        type: 'SET_EMPRESA_PENDENCIA',
        payload: data.empresa
      })
    }

    //mensagem de erro
    if(data.msg !== '') {
      dispatch({
        type: 'SET_MSG_ERRO_VALIDA',
        payload: data.msg
      })
    }else{
      dispatch({
        type: 'RESET_MSG_ERRO_VALIDA',
        payload: data.msg
      })
    }

    dispatch({
      type: "SALVAR",
      payload: newForm,
    });

  } catch (error) {
    //Temporário, melhorar isso quando a API devolver o erro correto
    if(error.message == "Cannot read properties of undefined (reading 'profissional')"){
      dispatch({
        type: 'SET_MSG_ERRO_VALIDA',
        payload: 'Erro no preenchimento no formulário. Clique em VOLTAR e tente novamente.'
      })
    }else{
      dispatch({
        type: 'RESET_MSG_ERRO_VALIDA',
        payload: 'Erro no preenchimento no formulário. Clique em VOLTAR e tente novamente.'
      })
    }
  }
  finally{
    return
  }
};

const montaAnexos = (data) => {

  if (data.empresa === undefined) {
    data.empresa = null;
  } else {
    data.empresa.anexos.map((anexo, index) => {
      anexo.file = null;
    });
  }

  if (data.profissional === undefined) {
    data.profissional = null;
  } else {
    data.profissional.map((pro, index) => {
      if (pro.anexos !== undefined) {
        pro.anexos.map((anexo) => {
          anexo.file = null;
        });
      }
      else{
        dispatch({
          type:'SET_FUNCIONARIOS_PENDENCIA',
          payload: pro
        })
      }
      let lista = data.profissional.filter(
        (element) => element.anexos !== undefined
      );
      if (lista.length) {
        data.profissional = lista;
      } else {
        delete data.profissional;
      }
    });
  }
  return data;
};

export const salvarAlteracoes = async (dados) => {
  
  const res = await apiEmpresa.post("/dados", dados);

  if(res.status === 200){
    dispatch({
      type: "SET_SOLICITACAO_ABERTA",
      payload: {
        status: res.data.statusId,
        msg:'',
        solic: res.data.solicitacao
      }
    });
   
    return {erro:false, msg:''};
  }
  else{
    return {erro: true, msg: res.response.data.msg}
  }

};

export const setLoading = (loading) => {
  dispatch({
    type:'SET_LOADING',
    payload: loading
  })
}

export const getCapitalSocial = async(tipoUsuario) => {
  const api = tipoUsuario === 'empresa' ? apiEmpresa : apiProfissional;
  try{
    const {data} = await api.get('/listarCapitalSocial')
    
    dispatch({
      type:'SET_CAPITAL_SOCIAL',
      payload: formatarCapital(data)
    })
  }
  catch(error){
    console.log('erro ao buscar capital social ', error)
  }
  finally{
    return
  }
}

export const getTipoEstab = async () => {
  try{
    const {data} = await apiEmpresa.get('/listaTipoEstab')
    dispatch({
      type:'SET_TIPO_ESTABELECIMENTO',
      payload: data
    })
    
  }
  catch(error){
    
  }
}

export const getSubtipoEstab = async (idtipo) => {
  if(idtipo === undefined){
    dispatch({
      type:'SET_SUBTIPO_ESTABELECIMENTO',
      payload: {
        idsubtipo:'0',
        subtipo:'Altere o tipo de estabelecimento'
      }
    })
  }
  else{
    try{
      const {data} = await apiEmpresa.get('/listaSubtipoEstab', {
          params: {
            idtipo
          }
      })
      dispatch({
        type:'SET_SUBTIPO_ESTABELECIMENTO',
        payload:data
      })
      
    }
    catch(error){
      console.log('error subtipo ', error);
    }
    finally{
      return
    }
  }
  return
}

export const getSolicitacoes = async (id, tipoUsuario) => {
  let api, parametro = '';
  if(tipoUsuario === 'empresa'){
    api = apiEmpresa;
    parametro = 'idEmp';
  }
  else{
    api = apiProfissional;
    parametro = 'idusuario';
  }
  
  
  try {
  const res = await api.get("/listarSolic", {
    params: {
      [parametro]: id
    },
  });
  if (res.status === 200) {
   return res.data;
  } else {
    return [];
  }
  
} catch (error) {
 
  return false
}
}

export const getRtHistoricoAceitas = async (id) => {
  try {
    const res = await apiProfissional.get("/rtHistorico", {
      params: {
        idProf : id
      },
    });
    if(res.status === 200) {
      return res.data;
    }else{
      return [];
    }
  }catch (error) {
    return false
  }
}

export const statusContinuaProf = async (idProf,idEmp,solicitacaoId) => {
  const res = await apiProfissional.post("/continua", {idProf,idEmp,solicitacaoId});
    if(res.status === 200) {
      return res.data
    }else {
    return false
  }
}

export const getIsencao = async (id) => {
  try {
    const { data } = await apiEmpresa.get("/formulario", {
      params: {
        idEmp: id,
      },
    });
    const isIsento = (data[0].tipo_propriedade === "Publica")
    dispatch({
      type: 'SET_ISENCAO',
      payload: isIsento
    })
  } catch (error) {
  }
}