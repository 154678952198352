const INITIAL_STATE = {
    formularioInicial: {
        idempresa:'',
        nome:'',
        razao:'',
        cnpj:'',
        capitalsocial_idcapitalsocial:'',
        empresa_categoria:'',
        cep:'',
        logradouro:'',
        numero:'',
        complenum:'',
        bairro:'',
        cidade:'',
        uf_endereco:'',
        hr_seg: "",
        hr_ter: "",
        hr_qua: "",
        hr_qui: "",
        hr_sex: "",
        hr_sab: "",
        hr_dom: "",
        telefone:"",
        celular:"",
        email1:"",
        email2:"",
        idtipo:"",
        idsubtipo:"",
        tipo:"",
        subtipo:"",
        inscricao:"",
        isento:""
       
    },
    formularioTemporario:{
        idempresa:'',
        nome:'',
        razao:'',
        cnpj:'',
        capitalsocial_idcapitalsocial:'',
        empresa_categoria:'',
        cep:'',
        logradouro:'',
        numero:'',
        complenum:'',
        bairro:'',
        cidade:'',
        uf_endereco:'',
        hr_seg: "",
        hr_ter: "",
        hr_qua: "",
        hr_qui: "",
        hr_sex: "",
        hr_sab: "",
        hr_dom: "",
        telefone:"",
        celular:"",
        email1:"",
        email2:"",
        idtipo:"",
        idsubtipo:"",
        tipo:"",
        subtipo:"",
        inscricao:"",
        isento:""
       
    },
    formularioEditado: false,
    listaProfissionais: [],
    listaProfissionaisTemporario:[],
    formularioValido: true,    
    alteracoes:'',
    pendenciasFuncionarios:[],
    pendenciasEmpresa: '',
    msgErroValida:'',
    solicitacaoAberta:{
        status: "0",
        msg:'',
        solicitacaoId:'',
        boleto:'',
        mensagemPendencia: ''
    },
    capitalSocial:[],
    tipoDeEstabelecimento:[],
    subtipoDeEstabelecimento:[],
    loading:false    
}



function CRTReducer (state = INITIAL_STATE, action){
    if(action.type === 'GET_FORMULARIO'){
        return {
            ...state,
            formularioInicial:{
            idempresa: action.idempresa,
            nome: action.nome,
            razao: action.razao,
            cnpj: action.cnpj,
            capitalsocial_idcapitalsocial: action.capitalsocial_idcapitalsocial,
            empresa_categoria:action.empresa_categoria,
            cep:action.cep,
            logradouro:action.logradouro,
            numero:action.numero,
            complenum:action.complenum,
            bairro:action.bairro,
            cidade:action.cidade,
            uf_endereco:action.uf_endereco,
            hr_seg: action.hr_seg,
            hr_ter: action.hr_ter,
            hr_qua: action.hr_qua,
            hr_qui: action.hr_qui,
            hr_sex: action.hr_sex,
            hr_sab: action.hr_sab,
            hr_dom: action.hr_dom,
            telefone:action.telefone,
            celular:action.celular,
            email1:action.email1,
            email2:action.email2,
            idtipo: action.idtipo,
            idsubtipo:action.idsubtipo,
            tipo:action.tipo,
            subtipo:action.subtipo,
            inscricao:action.inscricao,
            isento:action.isento
            }
        }
    }

    if(action.type === 'SET_SOLICITACAO_ABERTA'){
        return {
            ...state,
            solicitacaoAberta: action.payload
        }
    }

    if(action.type === 'SET_FORMULARIO_TEMPORARIO'){
        return{
            ...state,
            formularioTemporario : action.payload,
            formularioEditado: action.formularioEditado
        }
    }

    if(action.type === 'RESET_FORMULARIO_TEMPORARIO'){
        return{
            ...state,
            formularioTemporario: INITIAL_STATE.formularioTemporario,
            formularioEditado:false
        }
    }

    if(action.type === 'GET_LISTA_PROFISSIONAIS'){
        return {
            ...state,
            listaProfissionais: action.payload
        }
    }

    if(action.type === 'SET_LISTA_PROFISSIONAIS_TEMPORARIO'){
        return{
            ...state,
            listaProfissionaisTemporario : action.payload,
            formularioEditado: action.formularioEditado
        }
    }

    if(action.type === 'RESET_PROFISSIONAIS_TEMPORARIO'){
        return{
            ...state,
            listaProfissionaisTemporario: INITIAL_STATE.listaProfissionaisTemporario,
            formularioEditado:false,
        }
    }

    if(action.type === 'FORMULARIO_VALIDO'){
        return{
            ...state,
            formularioValido: action.formularioValido
        }
    }

    if(action.type === 'SALVAR'){
        return {
            ...state,
            alteracoes: action.payload
        }
    }

    if(action.type === 'SET_FUNCIONARIOS_PENDENCIA'){
        const isReset = Array.isArray(action.payload) && !action.payload.length
       
       if(!isReset){
        action.payload = [...state.pendenciasFuncionarios,action.payload]
       }
        return {
            ...state,
            pendenciasFuncionarios: isReset ? [] : action.payload
        }
    }

    if(action.type === 'SET_EMPRESA_PENDENCIA'){
        return {
            ...state,
            pendenciasEmpresa: action.payload
        }
    }

    if(action.type === 'RESET_EMPRESA_PENDENCIA'){
        return{
            ...state,
            pendenciasEmpresa: INITIAL_STATE.pendenciasEmpresa
        }
    }

    if(action.type === 'SET_MSG_ERRO_VALIDA'){
        return {
            ...state,
            msgErroValida: action.payload
        }
    }

    if(action.type === 'RESET_MSG_ERRO_VALIDA'){
        return{
            ...state,
            msgErroValida: INITIAL_STATE.msgErroValida
        }
    }

    if(action.type === 'SET_LOADING'){
        return {
            ...state,
            loading: action.payload
        }
    }

    if(action.type === 'SET_CAPITAL_SOCIAL'){
        return {
            ...state,
            capitalSocial: action.payload
        }
    }

    if(action.type === "SET_TIPO_ESTABELECIMENTO"){
        return {
            ...state,
            tipoDeEstabelecimento: action.payload
        }
    }

    if(action.type === 'SET_SUBTIPO_ESTABELECIMENTO'){
        return {
            ...state,
            subtipoDeEstabelecimento: action.payload
        }
    }
    
    if(action.type === 'RESET_LISTA_PROFISSIONAIS'){
        return {
            ...state,
            listaProfissionais: INITIAL_STATE.listaProfissionais,
          }
    }
    if (action.type === 'SET_ISENCAO') {
        return {
            ...state,
            isIsento: action.payload
        }        
    }

    return state
}

export default CRTReducer;