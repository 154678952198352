import { makeStyles } from "@material-ui/core/styles";
import { StylesContext } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    grupo: {
      marginBottom: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    titulo: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    textField: {
      border: " solid 2px red",
    },
    pendente:{
      backgroundColor: 'rgb(255, 244, 229)',
    }
  }));
  
  export default useStyles;