const EstruturaDaPagina = ({children}) => {
    return (
      <div style={{
        display:'flex',
        flexDirection:'column',
        gap:'16px'
        }}>
					{children}
			</div>
    )
}

export default EstruturaDaPagina;