import { Typography, Button, Container } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";


// components
import News from "../news";

const Welcome = ({ auth })  => {
  const navigate = useNavigate();

  return (
    <Box
      component="main"
      sx={{ maxHeight: "725px", overflow: "auto", width: "100%" }}
    >
      <Container>
        <Box pt={5} >
          { /* <News /> :) */ }
          <Typography variant="h6" align="center">
            Bem-Vindo(a)!
          </Typography>

          <Typography variant="body" align="center" paragraph>
            Utilize o menu para ir à tabela de solicitações ou clique {" "}
            {auth.tipoUsuario === 'empresa' ? 'em uma das opções abaixo' : 'na opção abaixo'}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
          {auth.tipoUsuario === 'empresa' &&
            <Button 
              variant="outlined"
              color="primary" 
              onClick={() => navigate("/empSolic")}
            >
              Cadastrar Solicitação
            </Button>
          }
          <Button 
            variant="outlined"
            color="primary" 
            onClick={() => navigate("/histSolic")}
          >
            Histórico de Solicitações
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
});
export default connect(mapStateToProps)(Welcome);
