export const rotulosProfissional = {
    idprofissional: "idprofissional",
    nome: "Nome",
    cpf: "CPF",
    dtnasc: "Data de nascimento",
    inscricao: "Inscrição",
    categoria: "Categoria",
    complemento: "Complemento",
    cep: "CEP",
    logradouro: "Logradouro",
    numero: "Número",
    cidade: "Cidade",
    uf_endereco: "UF",
    pro_tipo: "Hierarquia",
    pro_salario: "Salário",
    pro_vinculo: "Vínculo",
    pro_hora_seg: "Segunda-feira",
    pro_hora_ter: "Terça-feira",
    pro_hora_qua: "Quarta-feira",
    pro_hora_qui: "Quinta-feira",
    pro_hora_sex: "Sexta-feira",
    pro_hora_sab: "Sábado",
    pro_hora_dom: "Domingo",
    dataini: "Data de início",
    datafim: "Data de fim",
    escala: "Trabalha por escala"
  }