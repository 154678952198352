import { Box, Typography, Alert } from "@mui/material";

const PagamentoIndisponivel = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Alert severity="warning">
        Preencha os dados da CR e os anexos (caso necessários) antes de
        prosseguir para pagamento
      </Alert>
      {/* <Typography variant='body'>Preencha os dados da CR e os anexos (caso necessários) antes de prosseguir para pagamento.</Typography> */}
    </Box>
  );
};

export default PagamentoIndisponivel;
