import { createTheme } from '@material-ui/core/styles';
import { common } from '@mui/material/colors';


const theme = createTheme({
  palette: {
    primary: {
      main: '#26397f',
      light:'#5963af',
      dark:'#001452',
      contrastText:'#ffffff'
    },
    secondary: {
      main: '#5abaa3',
      light:'#8dedd4',
      dark:'#228974',
      contrastText:'#000000'
    },
    background:{
      main: '#e9e9ea',
      light:'#ffffff',
      dark:'#b7b7b8',
      contrastText:'#000000'
    }
  },
  typography:{
    fontFamily: ['Josefin Sans', 'sans-serif'].join(',')
  },
  components: {
    MuiOutlinedInput:{
      styleOverrides:{
        root:{
          "& > fieldset": { borderColor: 'gray' },
        }
      }
    },
    MuiTable:{
      styleOverrides:{
        root:{
          border:'gray solid 1px'
        }
      }
    },
    MuiTableCell:{
      styleOverrides:{
        head:{
          backgroundColor: '#26397f',
          color: common.white
        },
        body:{
          fontSize:14
        }
      }
    },
    MuiTableRow:{
      styleOverrides:{
        root:{
          "&:nth-of-type(odd)": {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          "&:last-child td, &:last-child th": {
            border: 0,
          }
        }
      }
    },
    MuiAlert:{
      styleOverrides:{
        standardSuccess:{
          backgroundColor: "#c8e6c9",
          color: "rgb(30, 70, 32)"
        },
        standardError:{
          backgroundColor: "rgb(253, 237, 237)",
          color: "rgb(95, 33, 32)",
        },
        standardWarning:{
          backgroundColor:'rgb(255, 244, 229)',
          color:'rgb(102, 60, 0)'

        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
  }
  
});

export default theme;

