import {
  TableCell,
  TableHead,
  TableRow,
  Table,
  Box,
  TableBody,
  TextField,
  Link,
	Alert,
	Snackbar,
  TableContainer
} from "@mui/material";
import { useEffect, useState } from "react";
import EstruturaDaPagina from "../components/estruturaDaPagina";
import TituloDaPagina from "../components/tituloDaPagina";
import { formatarEndereco } from "../helpers";
import { removerAcentos } from "../helpers";
import SearchIcon from "@mui/icons-material/Search";
import { connect } from "react-redux";
import { setUsuario } from "../store/actions/AutenticacaoActions";
import { setLoading } from "../store/actions/CRTActions";
import Loading from "../components/loading";
import MensagemFeedback from "../components/mensagemFeedback";
import { useNavigate } from "react-router-dom";

const SelecionarEmpresa = ({ auth, loading, listaFiliais }) => {
  const navigate = useNavigate();
  const [empresasFiltradas, setEmpresasFiltradas] = useState(listaFiliais);
  const [searchText, setSearchText] = useState("");
	const [empresaSelecionada, setEmpresaSelecionada] = useState('');
  const handleChangeText = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText === "") setEmpresasFiltradas(listaFiliais);
    let regex = new RegExp(removerAcentos(searchText), "gi");
    let newList = "";

    newList = listaFiliais.filter(
      (emp) =>
        emp.inscricao.toString().match(regex) ||
        removerAcentos(emp.nome).match(regex) ||
        removerAcentos(
          formatarEndereco(emp.logradouro, emp.numero, emp.complenum, "")
        ).match(regex) ||
        removerAcentos(emp.bairro).match(regex) ||
        removerAcentos(emp.cidade).match(regex)
    );

    setEmpresasFiltradas(newList);
  }, [searchText]);

  const selecionarEmpresa = (id, nome, endereco) => {
		setLoading(true);
    setUsuario(id, nome, endereco );
    setEmpresaSelecionada(nome);
    setTimeout(() => {
      setLoading(false);
      setEmpresaSelecionada("");
      navigate('/')
    }, 2000);
  };

  return (
    <>
      <TituloDaPagina titulo="Selecionar Empresa" /> 
			{loading && (<Loading status={loading}/>)}
      <MensagemFeedback 
        open={empresaSelecionada.length}
        msg={`Empresa selecionada: ${empresaSelecionada}`}
        color='success'
      />
      <Box>
        <TextField
          size="small"
          sx={{ width: "450px", float: "right" }}
          variant="outlined"
          label="Pesquisar por inscrição ou endereço"
          type={"text"}
          InputProps={{
            endAdornment: <SearchIcon sx={{ opacity: 0.7 }} />,
          }}
          value={searchText}
          onChange={(e) => handleChangeText(e)}
        />
      </Box>
      <Box>
        <TableContainer>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "100px" }}>Inscrição</TableCell>
              <TableCell sx={{ width: "600px" }}>Nome</TableCell>
              <TableCell sx={{ width: "600px" }}>Endereço</TableCell>
              <TableCell sx={{ width: "250px" }}>Bairro</TableCell>
              <TableCell sx={{ width: "250px" }}>Cidade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {empresasFiltradas.map((emp) => (
              <TableRow>
                <TableCell>
                  <Link
                    onClick={() => selecionarEmpresa(emp.idEmp, emp.nome, formatarEndereco(emp.logradouro,
                      emp.numero,
                      emp.complenum,
                      ""))}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    {emp.inscricao}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    onClick={() => selecionarEmpresa(emp.idEmp, emp.nome, formatarEndereco(emp.logradouro,
                      emp.numero,
                      emp.complenum,
                      ""))}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    {emp.nome}
                  </Link>
                </TableCell>
                <TableCell>
                  {formatarEndereco(
                    emp.logradouro,
                    emp.numero,
                    emp.complenum,
                    ""
                  )}
                </TableCell>
                <TableCell>{emp.bairro}</TableCell>
                <TableCell>{emp.cidade}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.AutenticacaoReducer.autenticacao,
  loading: state.CRTReducer.loading,
  listaFiliais: state.AutenticacaoReducer.listaFiliais
});
export default connect(mapStateToProps)(SelecionarEmpresa);
